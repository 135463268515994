const errBg = '#ffebec'

/**
 * 共通スタイル
 */
const common = {
    '& .required::after' : {
        content : '" *"',
        color : 'red',
    },
    '& .value .MuiOutlinedInput-multiline' : {
        padding : 0,
    },
    '& .MuiFormGroup-root' : {
        display : 'inline-flex',
    },

    '& .err' : {
        paddingTop : 4,
        fontSize : '0.8rem',
        color : 'red',

        '& .MuiFormGroup-root' : {
            background : errBg,
        },
        '& .MuiFormControlLabel-root' : {
            color : 'black',
            background : errBg,
        },
        '& .MuiInputBase-root' : {
            background : errBg,
        },
    },
    '& .explain' : {
        paddingBottom : 4,
        fontSize : '0.8rem',
    },
    '& .value .multiSelect' : {
        width : '100%',
        padding: '4px 8px !important',
    },
}

/**
 * 問合せ情報用のスタイル
 */
export const inquiryStyle = theme => {
    return {
        root : {
            ...common,

            display: 'flex',
            alignItems: 'stretch',
            borderBottom : 'solid 1px #ccc',

            '& .MuiFormControl-root, .MuiInputBase-root' : {
                width : '100%',
            },
            '& .MuiSelect-outlined, .MuiOutlinedInput-inputMultiline' : {
                padding: '4px 8px !important',
            },
            '& .MuiIconButton-label' : {
                paddingBottom : 2,
            },
            '& .MuiRadio-colorPrimary:not(.Mui-checked)' : {
                color : 'rgba(0, 0, 0, 0.54)',
            },
            '& .label' : {
                flexBasis : '30%',
                padding : 10,
                background : '#eee',
                wordBreak : 'break-word',
            },
            '& .value' : {
                flexBasis : '70%',
                padding: 6,

                '& .attachedFile' : {
                    width : '200px',
                },
                '& .MuiFormGroup-root' : {
                    marginLeft : '10px',
                    marginTop : 4,
                },
            },
            '& .pcAddress' : {
                '& .MuiTextField-root' : {
                    width : '30% !important',
                },
                '& Button' : {
                    marginLeft : '10px',
                    padding : '4px',
                },
            }
        },
    }
}

/**
 * 社内カテゴリ情報用のスタイル
 */
export const categoryStyle = theme => {
    return {
        root : {
            ...common,

            border : 'solid 1px #ccc',
            marginBottom : 10,

            '& .label' : {
                padding : '10px 0 0 10px',
            },
            '& .value' : {
                width : '80%',
                margin : '0 0 20px 10px',

                '& .MuiFormGroup-root' : {
                    paddingLeft : '10px',
                },
                '& .MuiFormControl-root' : {
                    width : '100%',
                },
            },
        },
    }
}
