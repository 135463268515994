import ApplicationBar from './ApplicationBar'
import logo from './img/TORAY_rogo.svg'

const WcmsAppBar = ({dict}) => {
    const menus = [
        {n : dict.titles.問い合わせ一覧, p : '/'},
        {n : dict.titles.ライブラリ一覧, p : '/library'}
    ]

    return (
        <ApplicationBar
            logo={logo}
            sysName="Web Contact Management System"
            menus={menus}
        />
    )
}


export default WcmsAppBar

