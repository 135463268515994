import { createMuiTheme } from '@material-ui/core/styles'

const WcmsTheme = createMuiTheme({
    components : {
        MuiAppBar : {
            styleOverrides : {
                colorPrimary : {
                    color : 'rgba(0, 0, 0, 0.87) !important',
                    backgroundColor : '#fff !important',
                },
            },
        },
        MuiListItem : {
            styleOverrides : {
                root : {
                    display : 'flex !important',
                    justifyContent : 'flex-start !important',
                    padding : '2px 16px !important'
                },
            },
        },
        MuiChip : {
            styleOverrides : {
                root : {
                    backgroundColor : '#e0e0e0 !important',
                },
            },
        },
        MuiAlert : {
            styleOverrides : {
                standardSuccess : {
                    color : 'rgb(30, 70, 32) !important',
                    backgroundColor : 'rgb(237, 247, 237) !important',
                },
                standardInfo : {
                    color : 'rgb(13, 60, 97) !important',
                    backgroundColor : 'rgb(232, 244, 253) !important',
                },
                standardWarning : {
                    color : 'rgb(102, 60, 0) !important',
                    backgroundColor : 'rgb(255, 244, 229) !important',
                },
                standardError : {
                    color : 'rgb(97, 26, 21) !important',
                    backgroundColor : 'rgb(253, 236, 234) !important',
                },
            },
        },
        MuiSvgIcon : {
            styleOverrides : {
                root : {
                    width : '0.9em',
                },
            },
        },
        MuiMenuItem : {
            styleOverrides : {
                root : {
                    fontSize : 14,
                },
            },
        },
        MuiTypography : {
            styleOverrides : {
                root : {
                    fontSize : 14,
                },
            },
        },
        MuiInputBase : {
            styleOverrides : {
                input : {
                    fontSize : 14,
                },
            },
        },
    },
})

export default WcmsTheme
