const validate = (field, value, messages) => {
    if (field.required && Array.isArray(value) && !value.length) {
        return messages.必須エラー.replace('{label}', field.label)
    }
    if (field.required && !value) {
        return messages.必須エラー.replace('{label}', field.label)
    }
    // 文字の桁数チェックは、値が設定されていない場合も実施する
    // ※ kintoneの仕様により、最小値が設定されている場合に未入力不可のため
    if (field.maxLength || field.minLength) {
        const max = field.maxLength && Number(field.maxLength)
        const min = field.minLength && Number(field.minLength)
        if (!validRange(max, min, value.length)) {
            return messages.文字桁数エラー.replace('{label}', field.label).replace('{min}', field.minLength).replace('{max}', field.maxLength)
        }
    }
    // 値が設定されている場合は、値の妥当性チェックを行う
    if (value) {
        if (field.type === 'NUMBER' && !isNum(value)) {
            return messages.数値エラー.replace('{label}', field.label)
        }
        if (field.type === 'DROP_DOWN' && !validOptions(Object.keys(field.options), value)) {
            return messages.選択肢エラー.replace('{label}', field.label)
        }
        if (['CHECK_BOX', 'MULTI_SELECT'].includes(field.type) && !validOptions(Object.keys(field.options), value, true)) {
            return messages.選択肢エラー.replace('{label}', field.label)
        }
        if (field.maxValue || field.minValue) {
            const max = field.maxValue && Number(field.maxValue)
            const min = field.minValue && Number(field.minValue)
            if (!validRange(max, min, value)) {
                return messages.数値範囲エラー.replace('{label}', field.label).replace('{min}', field.minValue).replace('{max}', field.maxValue)
            }
        }
        if (field.code === 'emailAddress' && !validMail(value)) {
            return messages.アドレス形式エラー
        }
    }
}

const isNum = (val) => {
    return !Number.isNaN(Number(val))
}

/**
 * 選択肢の中の値かどうか
 * @param options 選択肢の配列
 * @param vals 入力値の配列
 * @param isMulti trueの場合は、valueを複数許可
 */
const validOptions = (options, val, isMulti = false) => {
    if (isMulti) {
        return Array.isArray(val) && val.every(v => options.includes(v))
    }
    return !Array.isArray(val) && options.some(v => val === v)
}

/**
 * 数値範囲チェック
 */
const validRange = (max, min, val) => {
    if (max && min) {
        return min <= val && val <= max
    } else if (max) {
        return val <= max
    } else if (min) {
        return min <= val
    }
    return true
}

/**
 * メールアドレスの形式チェック
 */
const validMail = (val) => {
    const mailRegex = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/

    return mailRegex.test(val)
}

export default validate