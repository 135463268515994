import { useState , useEffect, useCallback} from 'react'
import { useParams , useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Amplify, { API } from 'aws-amplify'
import WcmsContent from './WcmsContent'
import Inquiry from './Inquiry'
import History from './History'
import Category from './Category'
import OperationHistory from './OperationHistory'
import Comments from './Comments'
import AnswerSetting from './AnswerSetting'
import TelInquiry from './TelInquiry'
import TelInquiryRegister from './TelInquiryRegister'
import InvalidReason from './InvalidReason'
import awsconfig from './aws-exports'
import LinkInformation from './LinkInformation'

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const useStyles = makeStyles(() => ({
    root : {
        fontSize : '14px'
    }
}))

const Detail = ({
    isAdmin,              // システム管理者か否か
    formInfo,             // フォーム情報
    userInfo,             // ログインユーザ情報
    formApps,             // 担当フォームアプリ情報
    getMailtemplates,     // 指定メールテンプレートゲッター
    appInfoList,          // アプリ設定情報リスト
    setAppInfoList,       // アプリ設定情報リストセッター
    invalidReasonOpts,     // 無効化理由選択肢リスト
    allDepartments,       // 全部署の名前ID対応リスト
    setAllDepartments,    // 全部署の名前ID対応リストセッター
    dict,                 // 多言語辞書
    setErr,               // エラーセッター 
    showMsg               // メッセージ表示
}) => {
    const { appNo } = useParams()
    const [record, setRecord] = useState(null)
    const [isTel, setIsTel] = useState(false)
    const [isRequery, setIsRequery] = useState(false)
    const [telFields, setTelFields] = useState({})
    const [telForm, setTelForm] = useState(undefined)
    const [telCustomForm, setTelCustomForm] = useState(undefined)
    const [telValidateMsg, setTelValidateMsg] = useState({})
    const [cateFields, setCateFields] = useState({})
    const [cateForm, setCateForm] = useState(undefined)
    const [cateCustomForm, setCateCustomForm] = useState(undefined)
    const [cateValidateMsg, setCateValidateMsg] = useState({})
    const [mailContent, setMailContent] = useState({})
    const [mailValidateMsg, setMailValidateMsg] = useState({})
    const [inquiryFiles, setInquiryFiles] = useState({})
    const [historyFiles, setHistoryFiles] = useState([])
    const [levelItems, setLevelItems] = useState({})
    const [displayDataFlag, setDisplayDataFlag] = useState(false)
    const [isAssigner, setIsAssigner] = useState(false)
    const [isReplier, setIsReplier] = useState(false)
    const [currentAppNo, setCurrentAppNo] = useState("")
    const [visibleDepartmentIds, setVisibleDepartmentIds] = useState([])
    const [form, setForm] = useState([])
    const location = useLocation()

    const classes = useStyles()

    const setRevision= useCallback(
        r => setRecord(c => {
            c.$revision.value = r
            return c
        }),[]
    )
    const handleTelFields = useCallback(
        f => setTelFields(f)
        ,[]
    )
    const handleLevelItems = useCallback(
        l => setLevelItems(l)
        ,[]
    )
    const changeTelForm = useCallback(
        f => setTelForm(c => ({...c, ...f}))
        ,[]
    )
    const changeTelCustomForm = useCallback(
        // f {フィールドコード : value}
        // o フィールドコード ※住所検索の検索結果挿入時のみ
        // v 挿入する検索結果 ※住所検索の検索結果挿入時のみ
        (f, o, v) => {
            if (o && v) {
                // 住所検索項目で、検索結果の値を挿入する場合の処理
                setTelCustomForm(c => {
                    const value = c[o]
                    value[0] = v || ''
                    return {...c, [o] : value}
                })
            } else {
                setTelCustomForm(c => ({...c, ...f}))
            }
        }
        ,[]
    )
    const changeTelValidateMsg = useCallback(
        f => setTelValidateMsg(c => ({...c, ...f}))
        ,[]
    )
    const handleCateFields = useCallback(
        f => setCateFields(f)
        ,[]
    )
    const changeCateCustomForm = useCallback(
        // f {フィールドコード : value}
        // o フィールドコード ※住所検索の検索結果挿入時のみ
        // v 挿入する検索結果 ※住所検索の検索結果挿入時のみ
        (f, o, v) => {
            if (o && v) {
                // 住所検索項目で、検索結果の値を挿入する場合の処理
                setCateCustomForm(c => {
                    const value = c[o]
                    value[0] = v || ''
                    return {...c, [o] : value}
                })
            } else {
                setCateCustomForm(c => ({...c, ...f}))
            }
        }
        ,[]
    )
    const changeCateForm = useCallback(
        f => setCateForm(c => ({...c, ...f}))
        ,[]
    )
    const changeCateValidateMsg = useCallback(
        f => setCateValidateMsg(c => ({...c, ...f}))
        ,[]
    )
    const changeMailValidateMsg = useCallback(
        f => setMailValidateMsg(c => ({...c, ...f}))
        ,[]
    )

    const handleInquiryFiles = useCallback(
        (f, field) => {
            setInquiryFiles(c => ({...c, [field] : f}))
        },
        [],
    )

    const handleHistoryFiles = useCallback(
        (f, index) => {
            setHistoryFiles(c => {
                c[parseInt(index)] = f
                return [...c]
            })
        },
        [],
    )

    // 問合せ情報の取得
    useEffect(() => {(async () => {
        try {
            const recParam = new URLSearchParams(location.search).get('r')
            
            // 添付ファイル情報のクリア
            setHistoryFiles([])

            if (recParam) {
                const ret = await API.post(apiname, '/getContact', {
                    body : {
                        app : appNo,
                        id : recParam
                    }
                })
                if (ret.resultCode === '04') {
                    const err = new Error(`存在しないレコードNo NG (${ret.resultCode})`)
                    err.reason = 'incorrectUrl'
                    throw err
                }

                if (ret.resultCode !== '00') throw new Error(`問合せ取得API NG (${ret.resultCode})`)

                const devRecord = ret.record
                // 該当レコードの閲覧権限がある部署を抽出
                const devVisibleDepartmentIds = formInfo?.[appNo]?.departments
                if (!isAdmin && !devVisibleDepartmentIds.some(c => c === formApps.部署ID)) {
                    const err = new Error(`閲覧権限なし NG`)
                    err.reason = 'incorrectUrl'
                    throw err
                }
                setVisibleDepartmentIds(devVisibleDepartmentIds)

                // 一時保存中、もしくは回答履歴の最終行が空の場合行を追加しない
                if (devRecord.一時保存フラグ.value === 'true' || 
                    (devRecord.回答履歴テーブル.value.length > 0 && 
                    !(((devRecord.回答履歴テーブル.value.slice(-1)[0] || {}).value|| {}).件名 || {}).value)) {
                } else {
                    devRecord.回答履歴テーブル.value.push({
                        value : {
                            回答方法 : {
                                value : ''
                            },
                            件名 : {
                                value : ''
                            },
                            回答内容 : {
                                value : ''
                            },
                            添付ファイル : {
                                value : []
                            },
                        }
                    })
                }
                // 値があればオブジェクト配列として再配置
                if (devRecord.サブ担当者.value) devRecord.サブ担当者.value = JSON.parse(devRecord.サブ担当者.value)
                setRecord(devRecord)
                // 割振り担当者および回答担当者かどうか、一時保存かどうかの判定
                setIsTel(!/^(?:問合せ|資料請求)$/.test((((devRecord.操作履歴テーブル.value[0] || {}).value || {}).操作区分 || {}).value || ''))
                // 再問合せ後かどうかの判定
                setIsRequery((devRecord.操作履歴テーブル.value || []).find(c => ((c.value || {}).操作区分 || {}).value === '再問合せ'))
                setIsAssigner(devRecord.割振り担当.value ? JSON.parse(devRecord.割振り担当.value).some(c => c.割振り担当 === userInfo.email) : false)
                setIsReplier(devRecord.担当者メールアドレス.value === userInfo.email || (devRecord.サブ担当者.value || []).some(c => c.value === userInfo.email))
                setInquiryFiles({})
                // 最終の転送先フォームIDを取得
                setCurrentAppNo(((((devRecord.操作履歴テーブル.value.filter(c => c.value.転送先フォームアプリID.value)
                    .slice(-1)[0] || {}).value || {}).転送先フォームアプリID || {}).value || appNo))
            } else {
                if (!formApps.部署担当フォームアプリID.some(c => c === appNo)) {
                    const err = new Error(`閲覧権限なし NG`)
                    err.reason = 'incorrectUrl'
                    throw err  
                }
                setIsTel(true)
                setCurrentAppNo(appNo)
            }
            setDisplayDataFlag(true) 
        } catch (e) {
            setErr(e)
        }
    })()}, [appNo, location, isAdmin, formApps, setErr, userInfo, formInfo])

    // アプリ設定情報取得
    useEffect(() => {(async () => {
        try {
            if (!appInfoList[appNo]) {
                const ret = await API.post(apiname, '/getAppsInfo', {
                    body : {
                        app : [appNo]
                    }
                })
                if (ret.resultCode === '04') {
                    const err = new Error(`該当アプリなし NG (${ret.resultCode})`)
                    err.reason = 'incorrectUrl'
                    throw err
                }
                if (ret.resultCode !== '00') throw new Error(`アプリ設定情報取得API NG (${ret.resultCode})`)
                setAppInfoList({...appInfoList, [appNo] : ret[appNo]})
            }
        } catch (e) {
            setErr(e)
        }
    })()}, [appNo, appInfoList, setAppInfoList, setErr])

    /**
     * アプリ設定情報と問合せ情報をもとに、画面構築用の配列作成
     */
     useEffect(() => {(async () => {
        if (!appInfoList[appNo] || !record) return
        try {
            const transfered =  record.操作履歴テーブル.value.some(r => r.value.転送先フォームアプリID.value === appNo)
            let form
            // 転送されていないレコードのform情報作成
            if (!transfered) {
                const initialRecordLayouts = appInfoList[appNo].layout.find(r => r.code === "問合せ情報").layout
                form = initialRecordLayouts.reduce((l, r, i) => ({...l, [r.fields[0].code] : {
                    label : appInfoList[appNo].properties[r.fields[0].code].label.replace(/【.+】/, ''),
                    value : record[r.fields[0].code].value,
                    type : record[r.fields[0].code].type,
                    fieldcode : r.fields[0].code,
                    rowNum : i
                }}), {})
            } else {
                // 共通項目フォーム作成
                const commonRecordLayouts = appInfoList[appNo].layout.find(r => r.code === "共通項目").layout
                const commonForms = commonRecordLayouts.filter(l => record[l.fields[0].code].value)
                .reduce((l, r, i) => ({...l, [r.fields[0].code] : {
                    label : appInfoList[appNo].properties[r.fields[0].code].label.replace(/【.+】/, ''),
                    value : record[r.fields[0].code].value,
                    type : record[r.fields[0].code].type,
                    fieldcode : r.fields[0].code,
                    rowNum : i
                }}), {})

                // 個別項目フォーム作成
                const individualForms = record.個別項目.value.filter(k => k.value.入力値.value)
                .reduce((o, k, i) => {
                    const row = k.value
                    return {...o, [row.フィールドコード.value]: {
                        label : row.フィールド名.value,
                        value : row.入力値.value,
                        type : row.フィールドタイプ.value,
                        fieldcode : row.フィールドコード.value,
                        rowNum : commonRecordLayouts.length + i + 1
                    }}
                }, {})
                form = Object.assign(commonForms, individualForms)
            }
            const devform = Object.keys(form).reduce((a, c, i) => {
                if(['commentsQuestions', 'commentsQuestions_transfer'].includes(c)) {
                    if (i % 2 !== 0) {
                        a.push({label : '',value : '',type : '', fieldcode : '', hide : false})
                    }
                    a.push(form[c])
                    a.push({label : '',value : '',type : '', fieldcode : '', hide : true})
                } else {
                    a.push(form[c])
                }
                return a
            }, [])
            setForm(devform)
        }
        catch (e) {
            setErr(e)
        }
    })()}, [appNo, appInfoList, record, setErr])

    if (!displayDataFlag) return null

    return (
        <WcmsContent
            subtitle={dict.titles.問い合わせ詳細}
        >
            <div className={classes.root}>
                {!isTel ?
                <div>
                    <AnswerSetting
                        isAdmin={isAdmin}
                        isAssigner={isAssigner}
                        isReplier={isReplier}
                        showMsg={showMsg}
                        appNo={appNo}
                        currentAppNo={currentAppNo}
                        record={record}
                        getMailtemplates={getMailtemplates}
                        formInfo={formInfo}
                        appInfo={appInfoList[appNo]}
                        userInfo={userInfo}
                        allDepartments={allDepartments}
                        setAllDepartments={setAllDepartments}
                        dict={dict}
                        setErr={setErr}
                        form={form}
                    />
                    <Inquiry
                        isAdmin={isAdmin}
                        isRequery={isRequery}
                        isAssigner={isAssigner}
                        isReplier={isReplier}
                        showMsg={showMsg}
                        appNo={appNo}
                        record={record}
                        getMailtemplates={getMailtemplates}
                        formInfo={formInfo}
                        appInfo={appInfoList[appNo]}
                        userInfo={userInfo}
                        allDepartments={allDepartments}
                        setAllDepartments={setAllDepartments}
                        dict={dict}
                        setErr={setErr}
                        currentAppNo={currentAppNo}
                        form={form}
                        invalidReasonOpts={invalidReasonOpts}
                    />
                    <Comments
                        userInfo={userInfo}
                        appNo={appNo}
                        record={record}
                        dict={dict}
                        setErr={setErr}
                        formInfo={formInfo}
                        allDepartments={allDepartments}
                        setAllDepartments={setAllDepartments}
                        mailTemplate={getMailtemplates(appNo, formInfo, record, userInfo, ['コメント通知'], form)}
                        form={form}
                        visibleDepartmentIds={visibleDepartmentIds}
                    />
                </div>
                :
                <div>
                    <TelInquiry
                        showMsg={showMsg}
                        appNo={appNo}
                        currentAppNo={currentAppNo}
                        record={record}
                        setRevision={setRevision}
                        dict={dict}
                        setErr={setErr}
                        appInfo={appInfoList[appNo]}
                        fields={telFields}
                        handleFields={handleTelFields}
                        form={telForm}
                        changeForm={changeTelForm}
                        customForm={telCustomForm}
                        changeCustomForm={changeTelCustomForm}
                        inquiryFiles={inquiryFiles}
                        handleInquiryFiles={handleInquiryFiles}
                        validateMsg={telValidateMsg}
                        changeValidateMsg={changeTelValidateMsg}
                        levelItems={levelItems}
                        handleLevelItems={handleLevelItems}
                    />
                </div>
                }
                {(!isTel && (record && record.問合せステータス.value === '無効化')) &&
                <InvalidReason
                    record={record}
                    dict={dict}
                />
                }
                {(isTel || !(record && record.問合せステータス.value === '無効化')) && <>
                <History
                    isAdmin={isAdmin}
                    isAssigner={isAssigner}
                    isReplier={isReplier}
                    showMsg={showMsg}
                    appNo={appNo}
                    currentAppNo={currentAppNo}
                    record={record}
                    setRecord={setRecord}
                    isTel={isTel}
                    mailContent={mailContent}
                    setMailContent={setMailContent}
                    mailValidateMsg={mailValidateMsg}
                    getMailtemplates={getMailtemplates}
                    formInfo={formInfo}
                    appInfo={appInfoList[appNo]}
                    userInfo={userInfo}
                    allDepartments={allDepartments}
                    setAllDepartments={setAllDepartments}
                    dict={dict}
                    setErr={setErr}
                    validateMsg={mailValidateMsg}
                    changeValidateMsg={changeMailValidateMsg}
                    inquiryFiles={inquiryFiles}
                    historyFiles={historyFiles}
                    handleHistoryFiles={handleHistoryFiles}
                    form={form}
                    isRequery={isRequery}
                    />
                </>}
                <Category
                    isAdmin={isAdmin}
                    isAssigner={isAssigner}
                    isReplier={isReplier}
                    showMsg={showMsg}
                    appNo={appNo}
                    record={record}
                    setRevision={setRevision}
                    appInfo={appInfoList[appNo]}
                    dict={dict}
                    setErr={setErr}
                    isTel={isTel}
                    fields={cateFields}
                    handleFields={handleCateFields}
                    form={cateForm}
                    changeForm={changeCateForm}
                    customForm={cateCustomForm}
                    changeCustomForm={changeCateCustomForm}
                    validateMsg={cateValidateMsg}
                    changeValidateMsg={changeCateValidateMsg}
                    levelItems={levelItems}
                    handleLevelItems={handleLevelItems}
                />
                {formInfo[appNo].isDispLink &&
                    <LinkInformation
                        record={record}
                        dict={dict}
                    />
                }
                {!isTel &&
                    <OperationHistory
                        record={record}
                        dict={dict}
                    />
                }
                <TelInquiryRegister
                    isAdmin={isAdmin}
                    isAssigner={isAssigner}
                    isReplier={isReplier}
                    showMsg={showMsg}
                    appNo={appNo}
                    currentAppNo={currentAppNo}
                    record={record}
                    setRevision={setRevision}
                    dict={dict}
                    setErr={setErr}
                    isTel={isTel}
                    formInfo={formInfo}
                    userInfo={userInfo}
                    allDepartments={allDepartments}
                    setAllDepartments={setAllDepartments}
                    mailContent={mailContent}
                    telFields={telFields}
                    telForm={telForm}
                    changeTelForm={changeTelForm}
                    telCustomForm={telCustomForm}
                    telValidateMsg={telValidateMsg}
                    changeTelValidateMsg={changeTelValidateMsg}
                    cateFields={cateFields}
                    cateForm={cateForm}
                    changeCateForm={changeCateForm}
                    cateCustomForm={cateCustomForm}
                    cateValidateMsg={cateValidateMsg}
                    changeCateValidateMsg={changeCateValidateMsg}
                    mailValidateMsg={mailValidateMsg}
                    changeMailValidateMsg={changeMailValidateMsg}
                    inquiryFiles={inquiryFiles}
                    historyFiles={historyFiles}
                />
            </div>
        </WcmsContent>
    )
}

export default Detail
