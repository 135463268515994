import { makeStyles } from '@material-ui/core/styles'
import { useState , useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/core/Alert'

const useStyles = makeStyles(theme => ({
    root : {
        marginBottom : 8,

        '& .MuiSnackbar-root' : {
            top : 90,
            width : '80%',
        },
        '& .MuiAlert-root' : {
            boxShadow: '3px 3px 6px #888',
            width : '100%',
        },
    }
}))

const types = ['success', 'info', 'warning', 'error']


const SnackbarMessage = ({msg, closeMsg}) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        setOpen(msg !== null)
    }, [msg])

    if (!msg || !types.includes(msg.type)) return null
    const hideDuration = (msg.type === 'success' || msg.type === 'info') ? 4000 : null

    const onClose = () => {
        setOpen(false)
        closeMsg()
    }

    return (
    <div className={classes.root}>
        <Snackbar
            open={open}
            anchorOrigin={{vertical : 'top', horizontal : 'center'}}
            autoHideDuration={hideDuration}
            onClose={onClose}
        >
            <Alert
                onClose={closeMsg}
                severity={msg.type}>{msg.text}
            </Alert>
        </Snackbar>
    </div>
    )
}

export default SnackbarMessage

