import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

/*
 * 住所のカスタム項目作成コンポーネント
 */
const StAddress = ({dict, field, customItem, changeCustomForm, changeValidateMsg, customValMsg, changeCustomValMsg, addressValue, code}) => {

    const handleChange = (event, field, index) => {
        // エラーメッセージのリセット
        changeValidateMsg({[field.code] : ''})
        changeCustomValMsg({
            [field.code] : '',
            [code + '_pc_address'] : ''
        })

        const value = customItem
        value[index] = event.target.value

        changeCustomForm({[field.code] : value})
    }

    return (
        <div className='stAddress'>
            <div className={((customValMsg || [])[0] ? ' errForm' : '')}>
            {(addressValue || []).length > 0 ?
                <TextField
                    className="withLabel"
                    label={dict.labels.住所}
                    value={customItem[0] || (addressValue || [])[0]}
                    onChange={e => handleChange(e, field, 0)}
                    select
                >
                    {addressValue.map(s => 
                    <MenuItem
                        key={s}
                        value={s}
                    >
                        {s}
                    </MenuItem>
                    )}
                </TextField>
            :
                <TextField
                    className="withLabel"
                    InputLabelProps={{shrink : true}}
                    label={dict.labels.住所}
                    size="small"
                    value={customItem[0]}
                    onChange={e => handleChange(e, field, 0)}
                />
            }
            <TextField
                className="withLabel"
                InputLabelProps={{shrink : true}}
                label={dict.labels.番地}
                size="small"
                value={customItem[1]}
                onChange={e => handleChange(e, field, 1)}
                disabled={!customItem[0]}
            />
            </div>
            {customValMsg &&
                <div className="errMsg">
                    {customValMsg}
                </div>
            }
         </div>
    )
}

export default StAddress
