import { useState, useEffect, useMemo, memo } from 'react'
import MaterialTable from 'material-table'
import { makeStyles } from '@material-ui/core/styles'
import Amplify, { API } from 'aws-amplify'
import WcmsContent from './WcmsContent'
import awsconfig from './aws-exports'
import Spinner from './Spinner'
import PatchedPagination from './PatchedPagination'
import { format } from 'date-fns'

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const solidLine = 'solid 1px rgba(224, 224, 224, 1)'
const useStyles = makeStyles(theme => ({
    root : {
        paddingTop : '40px',

        '& .MuiTable-root' : {
            fontSize : '15px',
            borderCollapse: 'unset !important',
            '& tr' : {
                '& th' : {
                    padding : '12px 4px',
                    fontSize : '15px',
                    background : '#153f93',
                    color : '#fff',
                    ...widths,
                    '&:nth-child(1) span' : {
                        display : 'none',
                    },
                },
                '& .MuiTableCell-body' : {
                    padding : '12px 4px',
                    borderBottom: solidLine,
                    ...widths,
                    wordBreak : 'break-word',
                    whiteSpace: 'pre-wrap',
                    fontSize : '15px !important',
                }
            },
            '& .MuiTableSortLabel-root' : {
                color : '#fff',
                '&:hover' : {
                    color : 'rgba(255, 255, 255, 0.54)',
                },
                '& .MuiTableSortLabel-icon:hover' : {
                    color : 'rgba(255, 255, 255, 0.54)',
                },
                '&.MuiTableSortLabel-active' : {
                    fontWeight : 'bold',
                    color : '#fff',
                    '& .MuiTableSortLabel-icon' : {
                        color : '#fff',
                    }
                },
            }
        },
    },
}))

const headers = [
    'No',
    'ライブラリ名',
    'ファイル',
    '概要',
    '更新日時',
]

// 日時フォーマット
const dateFormat = t => t ? format(new Date(t), 'yyyy/MM/dd HH:mm:ss') : ''

// レコード一覧の列の幅設定
const widths = [ 45, 45, 280, 320, 360, 250 ]
    .reduce((o, w, i) => ({...o, [`&:nth-child(${i+1})`] : {minWidth : w, maxWidth : w}}), {})

const Library = memo(({
    dict,
    setErr,
    showMsg,
}) => {

const [libraryList, setLibraryList] = useState([])
const [spinnerOpen, setSpinnerOpen] = useState(false)
const classes = useStyles()

const columns = useMemo(() => headers.map(h => ({field : h, title : dict.labels[h]})), [dict])

    // ライブラリ取得&整形
    useEffect(() => {(async () => {
        try {
            setSpinnerOpen(true)
            const ret = await API.post(apiname, '/getLibrary')
            if (ret.resultCode !== '00') throw new Error(`ライブラリ取得API NG (${ret.resultCode})`)
            const tempLibraryList = ret.records.map( c => ({
                No : c.ソート番号.value,
                ファイル : (c.ファイル.value[0] || {}).name,
                ライブラリ名 : c.表示名.value,
                filekey : (c.ファイル.value[0] || {}).fileKey,
                contentType : (c.ファイル.value[0] || {}).contentType,
                概要 : c.概要.value,
                更新日時 : dateFormat((c.更新履歴.value.slice(-1)[0] || {}).value.日時.value)
            })).sort((a, b)  =>  Number(a.No || 99999) - Number(b.No || 99999))
            setLibraryList(tempLibraryList)
            setSpinnerOpen(false)
        } catch (e) {
            setSpinnerOpen(false)
            setErr(e)
        }
    })()}, [setErr])

    const execDownload = async (fileKey, contentType, fileName) => {
        try {
            if (!fileKey || !contentType || !fileName) return
            setSpinnerOpen(true)
            const ret = await API.post(apiname, '/downloadFile', {body : {file : fileKey}})
            if (ret.resultCode !== '00') throw new Error(`ファイルダウンロードAPI NG (${ret.resultCode})`)
            const file = base64toBlob(ret.data, contentType)
            const link = document.createElement('a')
            link.download = fileName
            link.href = URL.createObjectURL(file)
            setSpinnerOpen(false)
            link.click()
            URL.revokeObjectURL(link.href)
        } catch (e) {
            setSpinnerOpen(false)
            console.log(e)
            showMsg('error', dict.messages.ダウンロード失敗)
        }
    }

    return (<>
        <WcmsContent
            subtitle={dict.titles.ライブラリ一覧}
            msg={null}
        >
            <div className={classes.root}>
                <MaterialTable
                    columns={columns}
                    data={libraryList}
                    actions={[{
                        icon : 'download',
                        onClick : (e, r) => execDownload(r.filekey, r.contentType, r.ファイル),
                        iconProps : {fontSize : 'large'}
                    }]}
                    components={{
                        Pagination: PatchedPagination,
                      }}
                    options={{
                        paging : true,
                        toolbar : false,
                        draggable : false,
                        padding : 'dense',
                        pageSize : 10,
                    }}
                />
            </div>
        </WcmsContent>
        <Spinner spinnerOpen={spinnerOpen}/>
    </>)
})

const base64toBlob = (b, t) => {
    const bin = atob(b)
    const buff = new Uint8Array(bin.length)
    for (let i=0; i < bin.length; i++) {
        buff[i] = bin.charCodeAt(i)
    }
    return new Blob([buff.buffer], {type : t})
}

export default Library