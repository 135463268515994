import { useState, useCallback, useRef, memo } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Amplify, { API } from 'aws-amplify'
import MessageIcon from '@material-ui/icons/Message'
import Button from '@material-ui/core/Button'
import awsconfig from './aws-exports'
import { TextField, IconButton  } from '@material-ui/core'
import Spinner from './Spinner'
import AddressSelector from './AddressSelector'
import ReplyIcon from '@material-ui/icons/Reply'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'
import commonFunc from './commonFunc'

const border = 'solid 1px #ccc'
const useStyles = makeStyles(theme => ({
    root : {
        display : 'flex',
        position : 'fixed',
        top : 76,
        right : 0,
        height : '90%',
        zIndex : 100,

        '& .comment-tab' : {
            padding : 4,
            height : 35,
            color : '#fff',
            backgroundColor : '#3498db',
            cursor : 'pointer',

            '&.closed' : {
                backgroundColor : '#ccc',
            },
        },
        '& .comment-area' : {
            overflow: 'auto',
            width : 300,
            backgroundColor : '#fff',
            border : border,

            '& .post' : {
                padding : 6,
                borderBottom : border,
            },
            '& .edit-area' : {
                borderBottom : border,

                '& .MuiFormControl-root' : {
                    display : 'flex',
                    margin : 5,
    
                    '& .MuiInputBase-root' : {
                        padding : 5,
                        margin : 3,
                    },
                },
                '& .button-area' : {
                    display : 'flex',
                    justifyContent : 'space-around',
                    padding : 6,
                    '& .MuiButton-root' : {
                        minWidth : 100,
                    },
                },
                '& .autocomp-area' : {
                    width : '94.5% !important',
                },
            },
        },
        '& .comments' : {
            '& .comment' : {
                padding : 6,
                borderBottom : border,
                '& .header' : {
                    display : 'flex',
                    justifyContent : 'space-between',
                    '& .idName' : {
                        display : 'flex',
                        fontSize : '15px',
                        '& .name' : {
                            fontWeight : 600,
                            color : '#217dbb',
                            width : 130,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        },
                        '& .betweenSpace' : {
                            padding : '0px 3px',
                        },
                    }
                },
                '& .mentionName' : {
                    width : '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
                '& .date' : {
                    fontSize : '0.75rem',
                    paddingBottom : 4,
                    textAlign : 'right',
                },
                '& .text' : {
                    wordBreak : 'break-word',
                    whiteSpace : 'pre-wrap',
                }
            },
        },
        '& .spinnerDiv' : {
            display : 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '& .reply-button-area' : {
            display : 'flex',
            justifyContent : 'end',
            '& .icon-text' : {
                fontSize : '12px',
            },
            '& .MuiIconButton-root' : {
                width : 95,
            },
        },
    },
}))

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const Comments = memo(({
    userInfo,             // ログインユーザ情報
    dict,                 // 多言語辞書
    setErr,               // エラーセッター
    appNo,                // フォームアプリID
    record,               // 問合せ情報
    formInfo,             // フォーム情報
    allDepartments,       // 全部署の名前ID対応リスト
    setAllDepartments,    // 全部署の名前ID対応リストセッター
    mailTemplate,         // メールテンプレート
    form,                 // 画面構築用フォーム情報
    visibleDepartmentIds, // 詳細画面を閲覧可能な部署ID配列
}) => {
    const [open, setOpen] = useState(record.コメント一覧?.value?.length > 0)
    const [input, setInput] = useState(false)
    const [inputComment, setInputComment] = useState('')
    const [spinnerOpen, setSpinnerOpen] = useState(false)
    const [target, setTarget] = useState([])
    const [validate, setValidate] = useState({})
    const classes = useStyles()
    const scrollRef = useRef(null)
    const history = useHistory()
    
    /**
     * コメントを登録する
     * コメント登録後にコメントの再取得を行う
     */
    const insert = async c => {
        setSpinnerOpen(true)
        // サジェスト項目に連絡先が入力されていた場合、メール送信パラメタを追加する
        // 最新の転送先のドメインフィールドの値を取得し、メール送信パラメタに追加
        try {
            const devMail = target.length ? {
                Destination : {
                    ToAddresses : target.map(c => c.value),
                },
                Message : {
                    Subject : mailTemplate?.コメント通知[0]?.件名,
                    Text : (mailTemplate?.コメント通知[0]?.本文 || '').replace(/{commentText}/g, inputComment)
                },
                noReply : true,
                domain : formInfo[appNo].domain
            } : null
            const newRow = {
                登録者 : userInfo.name,
                登録者メールアドレス : userInfo.email,
                メンション先 : target.map(t => t.label).join("\n"),
                メンション先メールアドレス : target.map(t => t.value).join("\n"),
                日時 : new Date().toISOString(),
                コメント : inputComment
            }
            const params = {
                app : appNo,
                id : record.$id.value,
                revision : record.$revision.value,
                コメント一覧 : record.コメント一覧.value.map(c => ({行番号 : c.id})).concat(newRow),
            }
            if (devMail) params.Mails = [devMail]
            const result = await API.post(apiname, '/registerContact', {body : params})
            if (result.resultCode !== '00') {
                throw new Error(`レコード登録更新API NG (${result.resultCode})`)
            }
            setInputComment('')
            history.push(`/detail/${appNo}?r=${result.records.id}`)
            setSpinnerOpen(false)
        }
        catch (e) {
            setSpinnerOpen(false)
            setErr(e)
        }
    } 

    const handleClick = () => {
        if (inputComment) {
            insert()
        }
    }

    const handleInput = e => {
        setInputComment(e.target.value)
    }

    /**
     * コメント欄の最上部にスクロールされるfnc
     */
    const scrollToInputForm = useCallback(() => {
        scrollRef?.current?.scrollIntoView({
            block: 'end',
        })
    }, [ scrollRef ])
 
    /**
     * 返信、全員返信ボタン押下時ハンドラ
     */
    const replyBtnClick = useCallback((index, isAll) => {
        setInput(true)
        const mentionInfo = {
            from : {
                label : record.コメント一覧.value[index].value.登録者.value,
                value : record.コメント一覧.value[index].value.登録者メールアドレス.value
            },
            to : record.コメント一覧.value[index].value.メンション先メールアドレス.value.split("\n").map((m, i) => ({
                label: record.コメント一覧.value[index].value.メンション先.value.split("\n")[i],
                value: m
            }))
        }
        if (!record.コメント一覧.value[index].value.登録者メールアドレス.value) setTarget([])
        else {
            // ログインユーザーは除外＆投稿者メンション先のメアド重複削除
            if (isAll) setTarget([...new Map([mentionInfo.from].concat(mentionInfo.to).filter(c => c.value && userInfo.email !== c.value).map(v => [v.value, v])).values()])
            // ログインユーザーは除外
            else setTarget([mentionInfo.from].filter(c => c.value && userInfo.email !== c.value))
        }
        scrollToInputForm()
    }, [record, userInfo, scrollToInputForm])
 

    return (
        <div className={classes.root}>
            <div
                className={'comment-tab ' + (!open && 'closed')}
                onClick={() => setOpen(!open)}
            >
                <MessageIcon
                    fontSize="large"
                />
            </div>
            {open && 
            <div className="comment-area">
                <div ref={scrollRef}/>
                {!input &&
                <div className="post">
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setTarget([])
                            setInput(true)
                        }}
                    >
                        {dict.labels.コメントする}
                    </Button>
                </div>
                }
                {input &&
                <div className="edit-area">
                    <AddressSelector
                        onChange={v => {
                            setValidate({})
                            setTarget(v)
                        }}
                        value={target}
                        departments={visibleDepartmentIds}
                        allDepartments={allDepartments}
                        setAllDepartments={setAllDepartments}
                        setErr={setErr}
                        multi={true}
                        validate={validate.target}
                    />
                    <TextField
                        multiline
                        minRows={4}
                        value={inputComment}
                        onChange={e => handleInput(e)}
                    />
                    <div className="button-area">
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setInput(false)
                                setInputComment('')
                                setTarget([])
                            }}
                        >
                            {dict.labels.キャンセル}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleClick()
                                setInput(false)
                            }}
                            disabled={inputComment === ''}
                        >
                            {dict.labels.書き込む}
                        </Button>
                    </div>
                </div>
                }
                <div className="comments">
                    {record.コメント一覧.value.toReversed().map((c, i, a) => 
                    <div key={i} className="comment">
                        <div className="header">
                            <div className="idName">
                                <div className="id">{`${a.length - i}`}</div>
                                <div className="betweenSpace">{`:`}</div>
                                <div
                                    className="name"
                                    title={`${c.value.登録者.value}`}
                                >
                                    {`${c.value.登録者.value}`}
                                </div>
                            </div>
                            <div className="date">{commonFunc.dateFormat(c.value.日時.value)}</div>
                        </div>
                        {c.value.メンション先.value.split("\n").filter(m => m).map((m, i) => (
                            <div
                                key = {i}
                                className="mentionName"
                                title={m}
                            >
                                { `@${m}`}
                            </div> 
                        ))}
                        <div className="text">{c.value.コメント.value}</div>
                        <div className="reply-button-area">
                            <IconButton
                                onClick={() => replyBtnClick(a.length - i - 1, false)}
                            >
                                <ReplyIcon
                                    variant="outlined"
                                    size="small"
                                />
                                <div className='icon-text'>{dict.labels.返信}</div>
                            </IconButton>
                            <IconButton
                                onClick={() => replyBtnClick(a.length - i - 1, true)}
                            >
                                <ReplyAllIcon
                                    variant="outlined"
                                    size="small"
                                />
                                <div className='icon-text'>{dict.labels.全員返信}</div>
                            </IconButton>
                        </div>
                    </div>
                    )}
                </div>
            </div>
            }
            <Spinner spinnerOpen={spinnerOpen}/>
        </div>
    )
})

export default Comments
