/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:53f41432-7601-449a-a1ca-51ee6c59fbda",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_roWBtYDEJ",
    "aws_user_pools_web_client_id": "6vgqbadctpcu1mre61g1sivbmp",
    "oauth": {
        "domain": "toraywcms-develop.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://develop-wcms.dev-wcms-canon-electec.com/",
        "redirectSignOut": "https://develop-wcms.dev-wcms-canon-electec.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "wcmsbucket164426-develop",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "wcmsapi",
            "endpoint": "https://03hc3zjpzj.execute-api.ap-northeast-1.amazonaws.com/develop",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "toraywcms-20210701165327-hostingbucket-develop",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d3d4xnrtra4z92.cloudfront.net"
};


export default awsmobile;
