import { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import WcmsGroup from './WcmsGroup'

const solidLine = 'solid 1px #ccc'
const useStyles = makeStyles((theme) => ({
    root : {
        borderCollapse: 'collapse',
        width : 1040,

        '& th, td' : {
            border : solidLine,
            padding : '4px 4px',
        },
        '& th' : {
            fontWeight : 'normal',
            backgroundColor : '#eee',
            textAlign : 'left'
        },
        '& th.status' : {
            width : 200,
        },
        '& th.URL' : {
            width : 700,
        },
        '& td' : {
            textAlign : 'left'
        },
    }
}))

const LinkInformation = memo(({
    record,              // 問合せ情報
    dict,                // 多言語辞書
}) => {
    const classes = useStyles()

return (<>
        <WcmsGroup groupTitle={dict.labels.Salesforce連携情報}>
            <table className={classes.root}>
                <thead>
                    <tr>
                        <th className='status'>{dict.labels.連携状況}</th>
                        <th className='URL'>{dict.labels.URL}</th>
                    </tr>
                </thead>
            {record && 
                <tbody>
                    <tr>
                        <td>
                            <div>{(dict.values.連携状況.find(d => d.value === (record.SFDC連携状況_問合せ || {}).value) || {}).name || ''}</div>
                        </td>
                        <td>
                        <div>{(record.連携先URL || {}).value}</div>
                        </td>
                    </tr>
                </tbody>
            }
            </table>
        </WcmsGroup>
    </>)
})

export default LinkInformation