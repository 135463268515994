import { useEffect } from 'react'
import Amplify, { API } from 'aws-amplify'
import awsconfig from '../aws-exports'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

/**
 * 階層項目のカスタム項目作成コンポーネント
 * 
 *   field            : フィールドコード
 *   customItem       : 入力フォーム生成用の配列
 *   changeCustomForm : フィールド変更関数
 *   changeField      : フィールド変更関数
 *   levelCodes       : 階層項目のフィールド項目
 *   selected         : 選択中の階層項目値の配列
 *   changeSelected   : 選択中の階層項目変更関数
 */
const LevelItems = ({field, customItem, changeCustomForm, changeValidateMsg, levelCodes, selected, changeSelected, levelItems, handleLevelItems}) => {


    /**
     * 階層項目のカスタム項目が設定されている場合に実行する
     */
    useEffect(() => {(async () => {
        if (!levelCodes.length || Object.keys(levelItems).length > 0) return
        try {
            const ret = await API.post(apiname, '/getLevelItems', {body : {fieldCodes : levelCodes}})
                .catch(e => {throw new Error(e)})

            const items = {}
            ret.levelFields.forEach(r => {
                items[r.項目名] = items[r.項目名] || []
                items[r.項目名].push({
                    1 : r.選択値1,
                    2 : r.選択値2,
                    3 : r.選択値3
                })
            })
            handleLevelItems(items)
        }
        catch (e) {
        }
    })()}, [handleLevelItems, levelCodes, levelItems])

    const [, code, level] = field.code.match(/^(\S+)_level([1-3])/) || []

    const handleChange = (event, field) => {
        // エラーメッセージのリセット
        changeValidateMsg({[field.code] : ''})

        // 階層項目が変更されたら、下位層の階層項目はリセットする
        let l = {[field.code] : event.target.value}
        for (let i = Number(level); i <= 3; i++) {
            if (Number(level) !== i) {
                l = {...l, [code + '_level' + i] : ''}
            }
        }
        changeCustomForm(l)
        changeSelected({[code] : {...selected[code], ...{[level] : event.target.value}}})
    }

    const props = {
        value : customItem,
        onChange : e => handleChange(e, field)
    }

    const i = (levelItems[code] || []).filter(i => {
        if (!(selected[code] || {})[Number(level) - 1]) return true
        if (i[Number(level) - 1] === selected[code][Number(level) - 1]) return true
        return false
    })

    const items = Array.from(new Set(i.map(r => r[level])))

    return (
        <Box>
            {items.length > 0 && items[0] ? 
            <TextField
                select
                {...props}
            >
                {items.map(i =>
                    <MenuItem
                        key={i}
                        value={i}
                    >
                        {i}
                    </MenuItem>
                )}

            </TextField>
            :
            <TextField
                size="small"
                {...props}
            />
            }
        </Box>
    )
}

export default LevelItems
