import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const errBg = '#ffebec'
const useStyles = makeStyles(theme => ({
    client : {
        '& .client' : {
            display: 'flex',
            justifyContent : 'space-between',
        },
        '& .errForm' : {
            paddingTop : 0,

            '& .MuiInput-root' : {
                background : errBg,
            },
            '& .MuiFormControl-root' : {
                background : errBg,
            },
            '& .MuiFormGroup-root' : {
                background : errBg,
            },
        },
        '& .errMsg' : {
            paddingTop : 4,
            fontSize : '0.8rem',
            color : 'red',
        },
    }
}))

/*
 * 名前のカスタム項目作成コンポーネント
 */
const Client = ({dict, field, customItem, changeCustomForm, changeValidateMsg, customValMsg, changeCustomValMsg}) => {

    const classes = useStyles()

    const handleChange = (event, field, index) => {
        // エラーメッセージのリセット
        changeValidateMsg({[field.code] : ''})
        changeCustomValMsg({[field.code] : []})

        const value = customItem
        value[index] = event.target.value
        changeCustomForm({[field.code] : value})
    }

    return (
        <div className={classes.client}>
            <div className='client'>
                <div className={'first separate' + ((customValMsg || [])[0] ? ' errForm' : '')}>
                    <TextField
                        className="withLabel"
                        InputLabelProps={{shrink : true}}
                        label={dict.labels.姓}
                        size="small"
                        value={customItem[0]}
                        onChange={e => handleChange(e, field, 0)}
                    />
                </div>
                <div className={'last separate' + ((customValMsg || [])[1] ? ' errForm' : '')}>
                    <TextField
                        className="withLabel"
                        InputLabelProps={{shrink : true}}
                        label={dict.labels.名}
                        size="small"
                        value={customItem[1]}
                        onChange={e => handleChange(e, field, 1)}
                    />
                </div>
            </div>
            {customValMsg &&
                <div className="errMsg">
                    {customValMsg}
                </div>
            }
        </div>
    )
}

export default Client
