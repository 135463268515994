import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import SendModal from './SendModal'
import Spinner from './Spinner'

const errBg = '#ffebec'
const useStyles = makeStyles(theme => ({
    root : {
        fontSize : '14px',
        '& .contentErea' : {
            display : 'flex',
            flexDirection : 'column'
        },
        '& .reasonOptSelecter' : {
            width : 760,
        },
        '& .mailSelecter' : {
            paddingTop: 10,
            display : 'flex',
            justifyContent : 'flex-start',
            '& .selectLabel' : {
                paddingRight : 50
            }
        },
        '& .reason' : {
            marginTop : 10,
            width : 760,
            '& .MuiInputBase-multiline' : {
                paddingTop : '8.5px',
            },
        },
        '& .errMessage' : {
            paddingTop : 4,
            color : 'red',
        },
        '& .MuiTextField-root.err' : {
            backgroundColor : errBg,
        },
    },
}))

const InvalidateModal = ({
    showMsg,           // メッセージ表示関数
    operation,         // 操作
    closeModal,        // モーダルクローズ関数
    appNo,             // フォームアプリID
    currentAppNo,      // 現在の転送先フォームアプリID
    record,            // 問合せ情報
    formInfo,          // フォーム情報
    userInfo,          // ログインユーザ情報
    dict,              // 多言語辞書
    setErr,            // エラーセッター
    invalidReasonOpts, // 無効化選択肢
    mailtemplate       // メールテンプレート
}) => {
    const [reasonOpt, setReasonOpt] = useState('')
    const [reason, setReason] = useState('')
    const [mailSelect, setMailSelect] = useState('送信しない')
    const [mailContents, setMailContents] = useState([])
    const [sendParam, setSendParam] = useState(undefined)
    const [errMessage, setErrMessage] = useState('')
    const [spinnerOpen, setSpinnerOpen] = useState(false)
    const [buttonLabel, setButtonLabel] = useState(dict.labels.無効化 || '')
    const classes = useStyles()

    // 登録更新パラメータの作成
    useEffect(() => {
        if (!record) return
        setSendParam({
            app : appNo,
            id : record.$id.value,
            revision : record.$revision.value,
            問合せステータス : '無効化',
            無効化理由選択肢 : reasonOpt,
            無効化理由 : reason,
            SFDC連携状況_問合せ : record.連携先URL.value ? '連携待ち' : '未連携',
            操作履歴 : record.操作履歴テーブル.value.map(c => ({行番号 : c.id})).concat({
                操作区分 : '無効化',
                操作者 : userInfo.name,
                操作者メールアドレス : userInfo.email,
                補足 : ''
            })
        })
    }, [reason, reasonOpt, record, userInfo, appNo])

    // メールテンプレートからメール内容情報を作成
    useEffect(() => {
        // 依存関係ストッパー　依存関係にState値を追加する際はストッパーが必要か要確認
        if(!record || !mailtemplate || !formInfo || !currentAppNo) return
        const tmpMailContent = {
            title :dict.labels.お客様への通知メール,
            宛先 : {To : [record.emailAddress.value], Cc : [], Bcc : []},
            件名 : ((mailtemplate.無効化通知 || [])[0] || {}).件名,
            本文 : ((mailtemplate.無効化通知 || [])[0] || {}).本文,
            noReply : reasonOpt === '売り込み' ? true : false,
            domain : formInfo[currentAppNo].domain,
            displayName : true
        }
        if (mailSelect === '送信する' && reasonOpt === '売り込み') {
            setMailContents([tmpMailContent])
            setButtonLabel('')
        } else {
            setMailContents([])
            setButtonLabel(dict.labels.無効化)
        }
    }, [mailSelect, reasonOpt, dict, record, mailtemplate, formInfo, currentAppNo])

    const clearModal = () => {
        setErrMessage('')
        setReasonOpt('')
        setSendParam(undefined)
        setMailSelect('送信しない')
        closeModal()
    }

    // ヴァリデーション処理
    const validateFunc = () => {
        if(!reasonOpt) {
            setErrMessage(dict.messages.無効化理由未入力)
            setSpinnerOpen(false)
            return true
        }
    }

    return (
        <SendModal
            showMsg={showMsg}
            dict={dict}
            open={operation === '無効化'}
            closeModal={clearModal}
            title={dict.labels.無効化理由の入力}
            execLabel={dict.labels.無効化}
            mailContents={mailContents}
            sendParam={sendParam}
            setErr={setErr}
            validateFunc={validateFunc}
            successMessage={dict.messages.無効化完了}
            buttonLabel={buttonLabel}
        >
            <div className={classes.root}>
                <div className='contentErea'>
                    <div className='selectLabel'>{dict.labels.無効化理由}</div>
                        <TextField
                            className={'reasonOptSelecter' + (errMessage ? ' err' : '')}
                            value={reasonOpt}
                            onChange={e => {
                                setReasonOpt(e.target.value)
                                setErrMessage('')
                            }}
                            select
                            size='small'
                        >
                        {invalidReasonOpts?.map((opt, i) => 
                            <MenuItem key={i} value={opt}>{dict.values.無効化理由選択肢?.find(c => c.value === opt)?.name}</MenuItem>
                        )}
                        </TextField>
                {errMessage &&
                    <div className='errMessage'>
                        {errMessage}
                    </div>
                }
                {(reasonOpt && reasonOpt !== '売り込み') &&
                    <TextField
                        className={'reason'}
                        onChange={e => {
                            setErrMessage('')
                            setReason(e.target.value)
                        }}
                        multiline
                        minRows={8}
                    />
                }
                    {reasonOpt === '売り込み' &&
                    <div className='mailSelecter'>
                        <div className='selectLabel'>{dict.labels.無効化メール通知}</div>
                        <RadioGroup
                            value={mailSelect}
                            onChange={(e) => setMailSelect(e.target.value)}
                            row
                        >
                            <FormControlLabel
                                value='送信する'
                                control={<Radio color='primary' />}
                                label={<Typography>{dict.labels.送信する}</Typography>}
                            />
                            <FormControlLabel
                                value='送信しない'
                                control={<Radio color='primary' />}
                                label={<Typography>{dict.labels.送信しない}</Typography>}
                            />
                        </RadioGroup>
                    </div>
                    }
                </div>
            </div>
            <Spinner spinnerOpen={spinnerOpen}/>
        </SendModal>
    )
}

export default InvalidateModal
