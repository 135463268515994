import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const useStyles = makeStyles(theme => ({
    root : {
        '& .MuiDialogTitle-root' : {
            paddingTop : 20,
            paddingBottom : 15,
        },
        '& .DialogTitle' : {
            padding : 7,
            border : 'solid 1px #3f51b5',
            borderLeft: 'solid 5px #3f51b5',
        },
        '& .MuiDialogActions-spacing' : {
            paddingTop : 0,
            paddingBottom : 12,
            paddingLeft : 24,
            paddingRight : 24,
        },
    },
}))

const WcmsModal = ({open, closeModal, title, execLabel, execFunc, icon, children, dict}) => {
    const classes = useStyles()

    return (
        <Dialog
            className={classes.root}
            open={open}
            onClose={closeModal}
            maxWidth="md"
        >
            <DialogTitle>
                <div className='DialogTitle'>{title}</div>
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={closeModal}
                >
                    {dict.labels.キャンセル}
                </Button>
                <Button
                    variant="contained"
                    onClick={execFunc}
                >
                    {execLabel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default WcmsModal

