import { useState , useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddressSelector from './AddressSelector'
import modalStyle from './modalStyle'
import SendModal from './SendModal'

const useStyles = makeStyles(modalStyle)

 /**
  * 回答者変更モーダルコンポーネント
  * 
  */
const ChangeReplierModal = ({
    showMsg,           // メッセージ表示関数
    operation,         // 操作
    closeModal,        // モーダルクローズ関数
    appNo,             // フォームアプリID
    currentAppNo,      // 現在の転送先フォームアプリID
    record,            // 問合せ情報
    formInfo,          // フォーム情報
    userInfo,          // ログインユーザ情報
    allDepartments,    // 全部署の名前ID対応リスト
    setAllDepartments, // 全部署の名前ID対応リストセッター
    dict,              // 多言語辞書
    setErr,            // エラーセッター
    mailtemplate       // メールテンプレート
}) => {
    const [target, setTarget] = useState([])
    const [subTarget, setSubTarget] = useState([])
    const [validate, setValidate] = useState({})
    const [mailContents, setMailContents] = useState([])
    const [sendParam, setSendParam] = useState(undefined)
    const classes = useStyles()

    // メールテンプレートからメール内容情報を作成
    useEffect(() => {
        // 依存関係ストッパー　依存関係にState値を追加する際はストッパーが必要か要確認
        if(!mailtemplate || !formInfo || !currentAppNo) return
        const answerMailContent = {
            title : dict.labels.回答担当者への通知メール,
            宛先 : {To : target.map(c => c.value), Cc : subTarget.map(c => c.value), Bcc : []},
            件名 : ((mailtemplate.回答者通知 || [])[0] || {}).件名,
            本文 : ((mailtemplate.回答者通知 || [])[0] || {}).本文,
            noReply : true,
            domain : formInfo[currentAppNo].domain
        }
        setMailContents([answerMailContent])
    }, [target, subTarget, dict, mailtemplate, currentAppNo, formInfo])

    // 登録更新パラメータの作成
    useEffect(() => {
        if (!record || !mailContents || !target.length > 0) return
        setSendParam({
            app : appNo,
            id : record.$id.value,
            revision : record.$revision.value,
            担当者メールアドレス : target[0].value,
            担当者名 : target[0].label,
            サブ担当者 : subTarget.length ? JSON.stringify(subTarget) : '',
            SFDC連携状況_問合せ : record.連携先URL.value ? '連携待ち' : '未連携',
            操作履歴 : record.操作履歴テーブル.value.map(c => ({行番号 : c.id})).concat({
                操作区分 : '担当者変更',
                操作者 : userInfo.name,
                操作者メールアドレス : userInfo.email,
                補足 : target[0].label
            }),
        })
    }, [target, subTarget, mailContents, record, userInfo, appNo])

    // 初期値設定
    useEffect(() => {
        if (!record) return
        setTarget(record.担当者名.value ? [{label : record.担当者名.value, value : record.担当者メールアドレス.value}] : [])
        setSubTarget((record.サブ担当者.value || []).map(c => ({label : c.label, value : c.value})))
    }, [record])

    // キャンセルボタン押下時処理
    const cancelModal = useCallback(() => {
        setTarget(record.担当者名.value ? [{label : record.担当者名.value, value : record.担当者メールアドレス.value}] : [])
        setSubTarget((record.サブ担当者.value || []).map(c => ({label : c.label, value : c.value})))
        setValidate({})
        setMailContents([])
        closeModal()
    }, [record, closeModal])
    
    // ヴァリデーション処理
    const validateFunc = () => {
        if(!target.length > 0) {
            setValidate({target : true})
            return true
        }
    }

    return (
        <SendModal
            showMsg={showMsg}
            open={operation === "回答者変更"}
            closeModal={cancelModal}
            title={dict.labels.回答担当者の変更}
            validateFunc={validateFunc}
            dict={dict}
            setErr={setErr}
            mailContents={mailContents}
            sendParam={sendParam}
            record={record}
            successMessage={dict.messages.回答者変更完了}
        >
            <div className={classes.root}>
                <div className='rows'>
                    <div className='row'>
                        <div className='text'>{dict.labels.回答担当者}</div>
                        <div className='input'>
                            <AddressSelector
                                onChange={v => {
                                    setValidate({})
                                    setTarget(v)
                                }}
                                value={target}
                                departments={formInfo?.[appNo]?.departments || []}
                                allDepartments={allDepartments}
                                setAllDepartments={setAllDepartments}
                                setErr={setErr}
                                multi={false}
                                validate={validate.target}
                            />
                        {validate.target && <div className='errMessage'>{dict.messages.未入力エラー}</div>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='text'>{dict.labels.サブ担当者}</div>
                        <div className='input'>
                            <AddressSelector
                                onChange={v => {
                                    setSubTarget(v)
                                }}
                                value={subTarget}
                                departments={formInfo?.[appNo]?.departments || []}
                                allDepartments={allDepartments}
                                setAllDepartments={setAllDepartments}
                                setErr={setErr}
                                multi={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </SendModal>
    )
}

export default ChangeReplierModal
