import { useState, useEffect, useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import AttachedFile from './AttachedFile'
import constants from  './constants'

/*
 * kintoneの項目をフォーム入力項目として生成する共通コンポーネント
 *   dict       : 辞書
 *   field      : kintone API で取得した フィールド情報（該当フィールドコードの設定値）
 *   form       : 入力フォーム（レコード）の該当フィールドのObject
 *   changeField : フィールド変更関数
 *   changeValidateMsg : ヴァリデーションメッセージのチェンジャー
 *   inquiryFileValue  : 本フィールドの添付ファイルvalue
 *   handleInquiryFiles : 問合せ情報内の添付ファイルハンドラ
 *   record     : レコードデータ
 */

const InputValue = ({dict, field, form, changeField, changeValidateMsg, inquiryFileValue, handleInquiryFiles, record}) => {

    const [fileInfo, setFileInfo] = useState([])
    const [apiBrock, setApiBrock] = useState(true)
    const handleFileInfo = useCallback(
        f => {
            // エラーメッセージのリセット
            changeValidateMsg({[field.code] : ''})
            setFileInfo(f)
            handleInquiryFiles(f, field.code)
        },
        [handleInquiryFiles, changeValidateMsg, field.code]
    )
    
    // fileInfoに初期値を設定する
    useEffect(()  => {
        if (field.type !== 'FILE') return
        // すでに添付ファイルがある場合は該当の値をセットする
        if (inquiryFileValue) {
            setFileInfo(inquiryFileValue)
            return
        }
        let devFileInfo
        if (!record) {
            devFileInfo = []
            handleInquiryFiles(devFileInfo, field.code)
            setFileInfo(devFileInfo)
            setApiBrock(true)
        } else {
            devFileInfo = record[field.code].value || []
            handleInquiryFiles(devFileInfo, field.code)
            setFileInfo(devFileInfo)
            setApiBrock(false)
        }
    }, [record, field.code, field.type, inquiryFileValue, handleInquiryFiles])

    const handleChange = (event, code, type) => {
        if (type === 'CHECK_BOX') {
            const value = event.target.checked ? [...form, event.target.value] : form.filter(v => v !== event.target.value)
            changeField({[code] : value.sort(compareValue)})
        }  else if (type === 'MULTI_SELECT') {
            changeField({[code] : event.target.value.sort(compareValue)})
        }  else {
            changeField({[code] : event.target.value})
        }
        // エラーメッセージのリセット
        changeValidateMsg({[code] : ''})
    }

    const compare = (a, b) => a.index - b.index

    const compareValue = (a, b) => 
        (Object.values(field.options).find(c => c.label === a).index) - (Object.values(field.options).find(c => c.label === b).index)

    const hideTransferValue = (options) => {
        delete options[constants.transferSelect]
        return options
    }

    const props = {
        value : form,
        onChange : e => handleChange(e, field.code, field.type)
    }

    if (field.type === 'SINGLE_LINE_TEXT') {
        return (
            <TextField
                size="small"
                {...props}
            />
        )
    }
    else if (field.type === 'NUMBER') {
        return (
            <TextField
                size="small"
                {...props}
            />
        )
    }
    else if (field.type === 'MULTI_LINE_TEXT') {
        return (
            <TextField
                multiline
                minRows={6}
                {...props}
            />
        )
    }
    else if (field.type === 'RADIO_BUTTON') {
        return (
            <RadioGroup
                name={field.code}
                row={field.align === 'HORIZONTAL'}
                {...props}
            >
                {Object.values(hideTransferValue(field.options)).sort(compare).map((o, i) =>
                    <FormControlLabel
                        key={i}
                        value={o.label}
                        label={o.label}
                        control={<Radio color="primary" />}
                    />
                    )}
                </RadioGroup>
            )
    }
    else if (field.type === 'DROP_DOWN') {
        return (
            <TextField
                select
                size="small"
                {...props}
            >
                {Object.values(hideTransferValue(field.options)).sort(compare).map((o, i) =>
                    <MenuItem
                        key={i}
                        value={o.label}
                    >
                        {o.label}
                    </MenuItem>
                )}
            </TextField>
        )
    }
    else if (field.type === 'MULTI_SELECT') {
        return (
            <Select
                className='multiSelect'
                multiple
                {...props}
            >
            {Object.values(hideTransferValue(field.options)).sort(compare).map((o, i) =>
                <MenuItem
                    key={i}
                    value={o.label}
                >
                    {o.label}
                </MenuItem>
            )}
            </Select>
        )
    }
    else if (field.type === 'CHECK_BOX') {
            return (
                <FormGroup
                    row={field.align === 'HORIZONTAL'}
                    {...props}
                >
                    {Object.values(hideTransferValue(field.options)).sort(compare).map((o, i) =>
                        <FormControlLabel
                            key={i}
                            label={o.label}
                            control={<Checkbox
                                color="primary" 
                                value={o.label}
                                checked={form.includes(o.label)}
                            />}
                        >
                        </FormControlLabel>
                    )}
                </FormGroup>
            )
    }
    else if (field.type === 'FILE') {
        return (
            <div className='attachedFile'>
                <AttachedFile
                    dict={dict}
                    fileInfo={fileInfo}
                    handleFileInfo={handleFileInfo}
                    allowUploads={true}
                    apiBrock={apiBrock}
                    setApiBrock={setApiBrock}
                />
            </div>
        )
    }
    else {
        return null
    }
}

export default InputValue
