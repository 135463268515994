import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    root : {
        boxSizing : 'border-box',
        marginBottom : 14,
        width: 1064,

        '& .group-title' : {
            padding : '4px 6px',
            color : '#fff',
            backgroundColor : '#153f93',
        },

        '& .group-content' : {
            padding : 12,
            borderLeft : 'solid 1px #ccc',
            borderRight : 'solid 1px #ccc',
            borderBottom : 'solid 1px #ccc',
        }
    },
}))

const WcmsGroup = ({children, groupTitle}) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className="group-title">{groupTitle}</div>
            <div className="group-content">
                {children}
            </div>
        </div>
    )
}


export default WcmsGroup;
