import { useState , useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AddressSelector from './AddressSelector'
import modalStyle from './modalStyle'
import SendModal from './SendModal'

const useStyles = makeStyles(modalStyle)

/**
 * 回答モーダルコンポーネント
 * 
 */
const AnswerModal = ({
    showMsg,           // メッセージ表示関数
    operation,         // 操作
    closeModal,        // モーダルクローズ関数
    appNo,             // フォームアプリID
    currentAppNo,      // 現在の転送先フォームアプリID
    record,            // 問合せ情報
    telForm,           // 電話応対受付時の問合せ入力内容
    cateForm,          // 電話応対受付時のカテゴリ設定入力内容
    formInfo,          // フォーム情報
    userInfo,          // ログインユーザ情報
    allDepartments,    // 全部署の名前ID対応リスト
    setAllDepartments, // 全部署の名前ID対応リストセッター
    dict,              // 多言語辞書
    setErr,            // エラーセッター
    mailContent,       // メールコンテンツ
    isTel,             // 電話応対記録判定 
    inquiryFiles,      // 問い合わせ情報内添付ファイル情報
    historyFiles,      // 回答履歴内添付ファイル情報
    mailtemplate,      // メールテンプレート
}) => {
    const [bossTarget, setBossTarget] = useState([])
    const [otherTarget, setOtherTarget] = useState([])
    const [validate, setValidate] = useState({})
    const [mailContents, setMailContents] = useState([])
    const [sendParam, setSendParam] = useState(undefined)
    const classes = useStyles()

    // 担当者およびサブ担当者の送付先リスト作成
    const managerTarget = useMemo(() => {
        // 電話応対記録は空配列を返す
        if (isTel) return []
        let buf = [].concat(record.担当者メールアドレス.value || [], (record.サブ担当者.value || []).map(o => o.value))
        // ログインユーザー情報があれば送付先リストからログインユーザーを除外して返す
        if (userInfo) buf = buf.filter(c => c !== userInfo.email)
        return buf
    }, [isTel, record, userInfo])

    // メールテンプレートからメール内容情報を作成
    useEffect(()  => {
        // 依存関係ストッパー　依存関係にState値を追加する際はストッパーが必要か要確認
        if (!mailContent || !historyFiles || !mailtemplate || !formInfo || !currentAppNo) return
        const devMailContent = {
            宛先 : {To : [], Cc : [], Bcc : []},
            件名 : mailContent.件名,
            本文 : mailContent.本文,
            添付ファイル : historyFiles.slice(-1)[0] || []
        }
        if (mailContent.回答方法 === "メール") {
            devMailContent.title = dict.labels['お客様/上司への通知メール']
            devMailContent.宛先.To = [(((record || {}).emailAddress || {}).value || '')]
            devMailContent.宛先.Bcc = bossTarget.map(c => c.value).concat(managerTarget, otherTarget.map(c => c.value))
            devMailContent.noReply = false
            devMailContent.domain = formInfo[currentAppNo].domain
            devMailContent.displayName = true
        } else if (mailContent.回答方法 === "電話") {
            devMailContent.title = dict.labels.上司への通知メール
            devMailContent.宛先.To = bossTarget.map(c => c.value)
            devMailContent.宛先.Cc = managerTarget.concat(otherTarget.map(c => c.value))
            devMailContent.noReply = true
            devMailContent.domain = formInfo[currentAppNo].domain
            if (devMailContent.宛先.To.length === 0 && devMailContent.宛先.Cc.length > 0) {
                devMailContent.宛先.To = [devMailContent.宛先.Cc.shift()]
            }
            if (!isTel) {
                devMailContent.件名 = ((mailtemplate['電話回答通知'] || [])[0] || {}).件名
                devMailContent.本文 = (((mailtemplate['電話回答通知'] || [])[0] || {}).本文 || '')
                .replace(/{telReplyContent}/g, mailContent.本文)
            }
        }
        setMailContents([devMailContent])
    }, [bossTarget, managerTarget, otherTarget, record, historyFiles, dict, mailContent, mailtemplate, isTel, currentAppNo, formInfo])

    // 登録更新パラメータの作成
    useEffect(() => {
        if (mailContents.length === 0) return
        const answerParam = record ? record.回答履歴テーブル.value.map(c => ({行番号 : c.id})) : [{}]
        answerParam[answerParam.length - 1] = {
            回答方法 : mailContent.回答方法,
            件名 : mailContent.件名,
            回答内容 : mailContent.本文,
            添付ファイル : (historyFiles.slice(-1)[0] || []).map(c => (c.s3key)),
            SFDC連携状況_メール : '未連携',
        }
        const devSendParam = {
            app : appNo,
            問合せステータス : '回答済み',
            一時保存フラグ : false,
            回答 : answerParam,
            操作履歴 : (record ? record.操作履歴テーブル.value.map(c => ({行番号 : c.id})) : []).concat({
                操作区分 : isTel ? '電話応対記録' : '回答',
                操作者 : userInfo.name,
                操作者メールアドレス : userInfo.email,
                補足 : ''
            }),
        }
        if (record) {
            devSendParam.id = record.$id.value
            devSendParam.revision = record.$revision.value
            devSendParam.担当者名 = record.担当者名.value || userInfo.name
            devSendParam.担当者メールアドレス = record.担当者メールアドレス.value || userInfo.email
            devSendParam.SFDC連携状況_問合せ = record.連携先URL.value ? '連携待ち' : '未連携'
        }
        if (isTel) {
            devSendParam.担当者名 = userInfo.name
            devSendParam.担当者メールアドレス = userInfo.email
            devSendParam.SFDC連携状況_問合せ = record && record.連携先URL.value ? '連携待ち' : '未連携'
        }
        if (telForm) {
            devSendParam.問合せ情報 = {}
            Object.keys(telForm).forEach(c => {
                devSendParam.問合せ情報[c] = telForm[c]
            })
        }
        if (cateForm) {
            devSendParam.社内カテゴリ情報 = {}
            Object.keys(cateForm).forEach(c => {
                devSendParam.社内カテゴリ情報[c] = cateForm[c]
            })
        }
        setSendParam(devSendParam)
    }, [mailContents, bossTarget, otherTarget, record, telForm, cateForm, userInfo, appNo, historyFiles, mailContent, isTel])

    const cancelModal = () => {     
        setBossTarget([])
        setOtherTarget([])
        setValidate({})
        closeModal()
    }

    // ヴァリデーション処理
    const validateFunc = () => {
        if (formInfo[(currentAppNo || appNo)].noticeBoss === '必須') {
            if(!bossTarget.length > 0) {
                setValidate({bossTarget : true})
                return true
            }
        } else if (formInfo[(currentAppNo || appNo)].noticeBoss === '任意') {
            return false
        }   
    }    

    return (
        <SendModal
            showMsg={showMsg}
            open={operation === "回答" || operation === "電話応対記録"}
            closeModal={cancelModal}
            title={dict.labels.回答}
            validateFunc={validateFunc}
            dict={dict}
            setErr={setErr}
            mailContents={mailContents}
            sendParam={sendParam}
            record={record}
            inquiryFiles={inquiryFiles}
            historyFiles={historyFiles}
            successMessage={operation === "電話応対記録" ? dict.messages.問合せ登録完了 : dict.messages.メール送信完了}
        >
            <div className={classes.root}>
                <div className='rows'>
                    <div className='row'>
                        <div className='text'>{dict.labels.上司}</div>
                        <div className='input'>
                            <AddressSelector
                                onChange={v => {
                                    setValidate({})
                                    setBossTarget(v)
                                }}
                                value={bossTarget}
                                departments={formInfo?.[appNo]?.departments || []}
                                allDepartments={allDepartments}
                                setAllDepartments={setAllDepartments}
                                setErr={setErr}
                                multi={false}
                                validate={validate.bossTarget}
                            />
                            {validate.bossTarget && <div className='errMessage'>{dict.messages.未入力エラー}</div>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='text'>{dict.labels.その他}</div>
                        <div className='input'>
                            <AddressSelector
                                onChange={setOtherTarget}
                                value={otherTarget}
                                departments={formInfo?.[appNo]?.departments || []}
                                allDepartments={allDepartments}
                                setAllDepartments={setAllDepartments}
                                setErr={setErr}
                                multi={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </SendModal>
    )
}

export default AnswerModal
