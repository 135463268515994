const solidLine = 'solid 1px #ccc'
const errBg = '#ffebec'
const modalStyle = {
    root : {
        '& .rows': {
            '&:nth-child(1)' : {
                borderTop : solidLine,
            },
            '& .row': {
                display : 'flex',
                flexDirection : 'row',
                minHeight : 40,
                alignItems : 'scratch',
                flexWrap : 'nowrap',
                fontSize : '14px',
                borderBottom : solidLine,
                borderLeft : solidLine,
                borderRight : solidLine,

                '& > *' : {
                    padding : '6px 10px',
                }, 
                
                '& .text' : {
                    display: 'flex',
                    whiteSpace : 'pre-wrap',
                    wordBreak : 'break-word',
                    flexBasis : 160,
                    padding : '2px 4px',
                    alignItems : 'center',
                    background : '#eee',
                    borderRight : solidLine,
                },
                '& .input' : {
                    display: 'inline-block',
                    wordBreak : 'break-word',
                },
                '& .inputMulti' : {
                    display: 'inline-block',
                    wordBreak : 'break-word',
                    minWidth : 500,
                    '& .MuiInputBase-multiline' : {
                        padding : '4.5px 8px',
                        width : '100%',
                    },
                    '& .MuiFormControl-root' : {
                        width : '100%',
                    },
                },
                '& .radio' : {
                    display: 'inline-block',
                    justifyContent: 'center',
                    padding : '7.5px 0 7.5px 20px',
                },
                '& .errMessage': {
                    color : 'red',
                    paddingTop : 4,
                },
                '& .autocomplete' : {
                    display: 'inline-block',
                    wordBreak : 'break-word',
                    minWidth : 500,
                },
                '& .MuiAutocomplete-inputRoot' : {
                    paddingRight : '50px !important',
                    fontSize : '14px',
                },
                '& .select' : {
                    minWidth : 100,
                },
                '& .MuiSelect-root' : {
                    fontSize : '14px',
                    padding : '3px 0 3px 6px !important',
                },
                '& .MuiChip-root' : {
                    height : '21px !important'
                },
                '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-sizeSmall' : {
                    padding : 2,
                },
                '& .err' : {
                    background : errBg,
                },
                '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot.Mui-disabled' : {
                    background : '#eee',
                }
            },
        },
    },
    paper : {
        width : 500,
        fontSize : 14,
    },
    option : {
        paddingTop : 2,
        paddingBottom : 2,
    },
}

export default modalStyle