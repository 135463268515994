import { makeStyles } from '@material-ui/core/styles'
import InputValue from './InputValue'
import CustomItem from './CustomItem'

const InputItem = ({style, dict, field, form, changeField, validateMsg, changeValidateMsg, customUse, customValMsg, changeCustomValMsg, customItem, changeCustomForm, inquiryFiles, handleInquiryFiles,
    levelCodes, postCode, changePostCode, address, changeAddress, selected, changeSelected, levelItems, handleLevelItems, record, isTel}) => {

    const useStyles = makeStyles(theme => style(theme))
    const classes = useStyles()

    if (field.customNoDisp) return null
    return (
        <div className={classes.root}>
            <div className={'label' + (isTel && field.code === 'emailAddress' ? '' : field.required ? ' required' : '')}>{field.label}</div>
            <div className={'value' + (validateMsg ? ' err' : '')}>
                {field.explain &&
                <div className="explain">{field.explain}</div>
                }
                {(customUse[field.customType]) ?
                <CustomItem
                    dict={dict}
                    field={field}
                    customItem={customItem}
                    changeCustomForm={changeCustomForm}
                    changeValidateMsg={changeValidateMsg}
                    customValMsg={customValMsg}
                    changeCustomValMsg={changeCustomValMsg}
                    levelCodes={levelCodes}
                    postCode={postCode}
                    changePostCode={changePostCode}
                    address={address}
                    changeAddress={changeAddress}
                    selected={selected}
                    changeSelected={changeSelected}
                    levelItems={levelItems}
                    handleLevelItems={handleLevelItems}
                />
                :
                <InputValue
                    dict={dict}
                    field={field}
                    form={form}
                    customUse={customUse}
                    changeField={changeField}
                    changeValidateMsg={changeValidateMsg}
                    inquiryFileValue={inquiryFiles[field.code]}
                    handleInquiryFiles={handleInquiryFiles}
                    record={record}
                />
                }
                {validateMsg &&
                <div className="err">{validateMsg}</div>
                }
            </div>
        </div>
    )
}

export default InputItem
