import { makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import WcmsTheme from './WcmsTheme'
import logo from './img/TORAY_rogo.svg'
import errMessage from './errMessage.json'

const solidLine = 'solid 1px #ccc'
const useStyles = makeStyles(theme => ({
    root : {
        paddingTop : 4,
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'center',

        '& .MuiIconButton-root' : {
            marginLeft : -12,
            marginRight : 16,
            padding : 12,
            borderRadius : '50%',
        },
        '& .system-logo' : {
            height : 60,
        },
        '& .system-name' : {
            marginLeft : 100,
            paddingBottom : 8,
            fontSize : 30,
            fontWeight : 'bold',
            color : '#555',
            textShadow : '2px 2px 4px #bbb',
        },
        '& .contents' : {
            paddingTop : '100px',
            paddingBottom : '4px',
            display : 'flex',
            flexDirection : 'column',
            alignItems : 'center',
            width : 860,

            '& .title' : {
                margin : '15px 0 0 0',
                fontWeight : 'bold',
                fontSize : 30,
                alignSelf : 'flex-start'
            },
            '& .errContents' : {
                margin : '10px 0 15px 0',
                display : 'flex',
                flexDirection : 'column',
                '& .text' : {
                    whiteSpace : 'pre-wrap',
                    padding : '15px',
                    border : solidLine,
                    color : '#777777',
                    width : 800,
                },
                '& .button' : {
                    margin : '50px 0 80px 0',
                    alignSelf : 'center',
                },
            },
        },
    },
}))

const Err = ({err}) => {
    const classes = useStyles()
    console.log(err.stack)

    const lang = (navigator.language || '').split('-')[0]

    return (
        <ThemeProvider theme={WcmsTheme}>
            <div className={classes.root}>
                <AppBar>
                    <Toolbar>
                        <div>
                            <img className="system-logo" src={logo} alt="logo" />
                        </div>
                        <div className="system-name">Web Contact Management System</div>
                    </Toolbar>
                </AppBar>
                <div className="contents">
                    <div className="title">{errMessage.occurMatter[lang]}</div>
                    <div className='errContents'>
                        <div className="text">{err.reason === 'incorrectUrl' ? errMessage.incorrectUrl[lang] : errMessage.networkMatter[lang]}</div>
                        <Button
                            className="button"
                            variant="contained"
                            onClick={(e) => {window.location.href = '/'}}
                        >
                            {errMessage.button[lang]}
                        </Button>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default Err
