import { useState , useEffect, useCallback } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import AttachedFile from './AttachedFile'

const HistoryRow = ({
    dict,
    canAnswer,
    isTel,
    index,
    row,
    validateMsg,
    handleHistoryFiles,
    methodSelect,
    setMethodSelectHandler,
    templateOpt,
    setTemplateHandler,
    mailtemplates,
    title,
    changeTitle,
    answerContent,
    changeAnswerContent,
    onClick,
    record
}) => {
    const [fileInfo, setFileInfo] = useState([])
    const [apiBrock, setApiBrock] = useState(true)
    const handleFileInfo = useCallback(
        f => {
            setFileInfo(f)
            handleHistoryFiles(f, index)
        },
        [handleHistoryFiles, index]
    )

    // fileInfoに初期値を設定する
    useEffect(()  => {
        let devFileInfo
        if (!record) {
            devFileInfo = []
            setFileInfo(devFileInfo)
            handleHistoryFiles(devFileInfo, index)
            setApiBrock(true)
        } else {
            devFileInfo = record.回答履歴テーブル.value[index].value.添付ファイル.value || []
            setFileInfo(devFileInfo)
            handleHistoryFiles(devFileInfo, index)
            setApiBrock(false)
        }
    }, [record, index, handleHistoryFiles])

    return (<>
        <tr key={index}>
            <td>
            {!canAnswer ?
                <TextField
                    className="methodSelect"
                    disabled={true}
                    value={(dict.values.回答方法.find(c => c.value === ((row.value || {}).回答方法 || {}).value) || {}).name || ''}
                />
            :
                <TextField
                    className="methodSelect"
                    value={methodSelect}
                    onChange={e => setMethodSelectHandler(e.target.value)}
                    select
                >
                {!isTel &&
                    <MenuItem value="メール">{dict.values.回答方法.find(c => c.value === 'メール').name}</MenuItem>
                }
                    <MenuItem value="電話">{dict.values.回答方法.find(c => c.value === '電話').name}</MenuItem>
                </TextField>
            }
            </td>
            <td>
                <div className="body">
                {!canAnswer && <>
                    <TextField
                        disabled={true}
                        value={((row.value || {}).件名 || {}).value}
                    />
                    <TextField
                        multiline
                        minRows={2}
                        disabled={true}
                        value={((row.value || {}).回答内容 || {}).value}
                    />
                </>}
                {canAnswer && <>
                {methodSelect === 'メール' && <>
                    <div>
                        <TextField
                            className="selectField"
                            value={templateOpt}
                            onChange={e => setTemplateHandler(e.target.value)}
                            select
                        >
                            <MenuItem value={'---'}>--------</MenuItem>
                        {mailtemplates.map((c, i)  => (
                            <MenuItem
                                key={i}
                                value={i}
                            >
                                {c.テンプレートタイトル}
                            </MenuItem>
                        ))}
                        </TextField>
                    </div> 
                    <div>
                        <TextField
                            className={validateMsg.件名 ? "input err" : "input"}
                            type="text"
                            value={title}
                            onChange={e => changeTitle(e.target.value)}
                        />
                    {validateMsg.件名 &&
                        <div className="errMsg">{dict.messages.未入力エラー}</div>
                    }
                    </div>
                </>}
                <div>
                    <TextField
                        className={validateMsg.本文 ? "input err" : "input"}
                        multiline
                        minRows={8}
                        value={answerContent}
                        onChange={e => changeAnswerContent(e.target.value)}
                    />
                {validateMsg.本文 &&
                    <div className="errMsg">{dict.messages.未入力エラー}</div>
                }
                </div>
                </>}
                </div>
            </td>
            <td>
            {!canAnswer &&
                <div className="time">
                    {new Date(((row.value || {}).受信日時 || {}).value || ((row.value || {}).送信日時 || {}).value).toLocaleString()}
                </div>
            }
            </td>
            <td>
            {((!canAnswer && fileInfo.length > 0) || canAnswer) && 
                <AttachedFile
                    dict={dict}
                    className="select"
                    fileInfo={fileInfo}
                    handleFileInfo={handleFileInfo}
                    allowUploads={canAnswer}
                    apiBrock={apiBrock}
                    setApiBrock={setApiBrock}
                />
            }
            </td>
            {(canAnswer && !isTel )&&
            <td className="ctl">
                <Button
                    variant="contained"
                    onClick={onClick}
                >
                    {dict.labels.確認}
                </Button>
            </td>
            }
        </tr>
    </>)
}

export default HistoryRow
