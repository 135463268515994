import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import WcmsGroup from './WcmsGroup'
import InvalidateModal from './InvalidateModal'
import TransferModal from './TransferModal'
import CompleteModal from './CompleteModal'

const solidLine = 'solid 1px #ccc'
const useStyles = makeStyles((theme) => ({
    root : {
        width : 1040,
        '& .inquiry' : {
            display : 'flex',
            flexWrap : 'wrap',
            marginBottom : 10,
            whiteSpace : 'pre-wrap',

            '& .item' : {
                display : 'flex',

                '& > div' : {
                    padding : '2px 4px',
                    borderBottom : solidLine,
                },
                '& .label' : {
                    width : 160,
                    background : '#eee',
                    wordBreak : 'break-word',
                },
                '& .value' : {
                    width : 340,
                    borderLeft : solidLine,
                    borderRight : solidLine,
                    wordBreak : 'break-word',
                },
                '&:nth-child(-n+2) > div' : {
                    borderTop : solidLine,
                },
                '&:nth-child(odd) > .label' : {
                    borderLeft : solidLine,
                },
                '&.commentsQuestions' : {

                    '& .label' : {
                        borderLeft : solidLine,
                    },
                    '& .value' : {
                        width : 858,
                    },
                },
                '&.hidden' : {
                    display : 'none'
                }
            },
        },
        '& .buttons' : {
            display : 'flex',
            margin : '0 -4px 4px',
            '& button' : {
                margin: '0 4px',
            },
        },
    },
}))

const Inquiry = ({
    isAdmin,             // 管理者かどうかの真偽値
    isRequery,           // 再問合せかどうか
    isAssigner,          // 割振り担当者かどうかの真偽値
    isReplier,           // 回答担当者かどうかの真偽値
    showMsg,             // メッセージ表示関数
    appNo,               // フォームアプリID
    record,              // 問合せ情報
    getMailtemplates,    // メールテンプレートゲッター
    formInfo,            // フォーム情報
    appInfo,             // アプリ設定情報
    userInfo,            // ログインユーザ情報
    allDepartments,      // 全部署の名前ID対応リスト
    setAllDepartments,   // 全部署の名前ID対応リストセッター
    dict,                // 多言語辞書
    setErr,              // エラーセッター
    currentAppNo,        // 現在の転送先フォームアプリID
    form,                // 画面構築用フォーム情報
    invalidReasonOpts,   // 無効化理由選択肢
}) => {
    const [openComplete, setOpenComplete] = useState(false)
    const [operation, setOperation] = useState('')
    const closeModal = () => setOperation('')
    const classes = useStyles()

    return (<>
        <WcmsGroup groupTitle={dict.labels.問合せ情報}>
            <div className={classes.root}>
                <div className="inquiry">
                {form.map((c, i) => (
                    <div className={`item${['commentsQuestions', 'commentsQuestions_transfer'].includes(c.fieldcode) ? ' commentsQuestions' : '' }${c.hide ? ' hidden' : '' }`} key={i}>
                        <div className="label">{c.label}</div>
                        <div className="value">
                            {c.type === 'FILE' ?
                                c.value.reduce((o, r) => ([...o, r.name]), []).join("\n")
                                :
                                (Array.isArray(c.value) ? c.value.join("\n") : c.value)
                            }
                        </div>
                    </div>
                ))}
                </div>
                <div className='buttons'>
                {(record && (record.問合せステータス.value === '未対応' || record.問合せステータス.value === '受付済み') &&
                (isAdmin || isAssigner || isReplier)) &&
                <>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOperation('転送')
                        }}
                    >
                        {dict.labels.転送}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOperation('無効化')
                        }}
                    >
                        {dict.labels.無効化} 
                    </Button>
                </>
                }
                {((record && record.問合せステータス.value === '受付済み') &&
                (isAdmin || isAssigner || isReplier) && isRequery) &&
                    <Button
                        variant="contained"
                        onClick={() => setOpenComplete(true)}
                    >
                        {dict.labels.対応完了}
                    </Button>
                }
                </div>
            </div>
         </WcmsGroup>
         <InvalidateModal
            showMsg={showMsg}
            operation={operation}
            closeModal={closeModal}
            appNo={appNo}
            record={record}
            formInfo={formInfo}
            userInfo={userInfo}
            dict={dict}
            setErr={setErr}
            currentAppNo={currentAppNo}
            invalidReasonOpts={invalidReasonOpts}
            mailtemplate={getMailtemplates((currentAppNo || appNo), formInfo, record, userInfo, ['無効化通知'], form)}
         />
        <TransferModal
            showMsg={showMsg}
            operation={operation}
            closeModal={closeModal}
            appNo={appNo}
            record={record}
            formInfo={formInfo}
            userInfo={userInfo}
            dict={dict}
            setErr={setErr}
            mailtemplate={getMailtemplates((currentAppNo || appNo), formInfo, record, userInfo, ['転送通知(システム利用者)', '転送通知(システム利用者以外)'], form)}
            currentAppNo={currentAppNo}
        />
        <CompleteModal
            showMsg={showMsg}
            open={openComplete}
            closeModal={() => setOpenComplete(false)}
            appNo={appNo}
            record={record}
            userInfo={userInfo}
            dict={dict}
            setErr={setErr}
        />
    </>)
}

export default Inquiry