import { useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import WcmsGroup from './WcmsGroup'
import AnswerModal from './AnswerModal'
import HistoryRow from './HistoryRow'

const solidLine = 'solid 1px #ccc'
const errBg = '#ffebec'
const useStyles = makeStyles((theme) => ({
    root : {
        borderCollapse: 'collapse',
        width : '1043px',
        tableLayout : 'fixed',
        
        '& .method' : {
            width : '90px',
        },
        '& .content' : {
            width : '600px',
        },
        '& .datetime' : {
            width : '90px',
        },
        '& .attachedFile' : {
            width : '110px',
        },
        '& .confirm' : {
            width : '80px',
            display : 'none',
        },
        '& th, td' : {
            border : solidLine,
            padding : '4px 4px',
        },
        '& th' : {
            fontWeight : 'normal',
            backgroundColor : '#eee',
        },
        '& td:not(.ctl)' : {
            verticalAlign : 'top',
            width : '80px'
        },
        '& td.ctl' : {
            verticalAlign : 'bottom',
            border : 0,
            paddingBottom : 10,
            width : '80px'
        },
        '& .body' : {
            display : 'flex',
            flexDirection : 'column',
            width : 600,

            '& > *' : {
                marginBottom : 6,
            },
        },
        '& .selectField' : {
            width : 'fit-content',
        },
        '& .methodSelect' : {
            width : 90,
        },
        '& .selectField ,.methodSelect' : {
            '& .MuiOutlinedInput-input' : {
                padding : '4px 22px 4px 4px',
            },
        },
        '& .MuiSelect-iconOutlined' : {
            right : '0px !important',
        },
        '& .MuiSelect-selectMenu' : {
            whiteSpace : 'normal'
        },
        '& .MuiOutlinedInput-multiline' : {
            padding : '4px',
        },
        '& .input' : {
            width : 600,
        },
        '& .MuiTextField-root.err' : {
            backgroundColor : errBg,
        },
        '& .errMsg' : {
            paddingTop : 4,
            fontSize : '0.8rem',
            color : 'red',
        },
    }
}))


const History = ({
    isAdmin,             // 管理者かどうかの真偽値
    isAssigner,          // 割振り担当者かどうかの真偽値
    isReplier,           // 回答担当者かどうかの真偽値
    showMsg,             // メッセージ表示関数
    appNo,               // フォームアプリID
    currentAppNo,        // 現在の転送先フォームアプリID
    record,              // 問合せ情報
    setRecord,           // 問い合わせ情報セッター
    isTel,               // 電話応対受付か否か
    mailContent,         // メールコンテンツ
    setMailContent,      // メールコンテンツセッター
    getMailtemplates,    // メールテンプレ-トゲッター
    formInfo,            // フォーム情報
    appInfo,             // アプリ設定情報
    userInfo,            // ログインユーザ情報
    allDepartments,      // 全部署の名前ID対応リスト
    setAllDepartments,   // 全部署の名前ID対応リストセッター
    dict,                // 多言語辞書
    setErr,              // エラーセッター
    validateMsg,         // バリデートメッセージオブジェクト
    changeValidateMsg,   // バリデートメッセージセッター
    inquiryFiles,        // 問合せ情報内添付ファイル情報
    historyFiles,        // 回答履歴内添付ファイル情報
    handleHistoryFiles,  // 回答履歴内添付ファイル情報ハンドラ
    form,                // 画面構築用フォーム情報
    isRequery,           // 再問合せの真偽値
}) => {
    const [operation, setOperation] = useState('')
    const [methodSelect, setMethodSelect] = useState('電話')
    const [templateOpt, setTemplateOpt] = useState('---')
    const [answerContent, setAnswerContent] = useState('')
    const [mailtemplates, setMailtemplates] = useState([])
    const [title, setTitle] = useState('')

    const closeModal = () => setOperation('')

    const classes = useStyles()


    // メールテンプレートの置換処理
    useEffect(() => {
        if (mailtemplates.length > 0 || !record || !userInfo || !formInfo || !appNo || !form.length) return 
        const mailDiv = isRequery ? ['再回答通知', '回答通知'] : ['回答通知', '再回答通知']
        setMailtemplates(Object.values(getMailtemplates((currentAppNo || appNo), formInfo, record, userInfo, mailDiv, form)).flat() || [])
    }, [mailtemplates, appNo, formInfo, userInfo, record, getMailtemplates, form, isRequery, currentAppNo])

    // メール入力欄初期表示決定
    useEffect(() => {
        const editContents = (((record || {}).回答履歴テーブル || {}).value || []).slice(-1)[0] || {value : {}}
        if (isTel) {
            setMethodSelect('電話')
            setTitle(
                `【${((((formInfo || {})[appNo] || {}).formNames || []).find(e => e.lang === ((formInfo || {})[appNo] || {}).lang) || {}).formName}】`
                + dict.values.操作区分.find(c => c.value === '電話応対記録').name
            )
            setTemplateOpt('---')
            setAnswerContent((editContents.value.回答内容 || {}).value || '')
        } else if (record.一時保存フラグ.value === 'false' && mailtemplates.length > 0) {
            setMethodSelect('メール')
            setTemplateOpt(0)
            setTitle(isRequery ? 'Re:' + mailtemplates[0].件名 : mailtemplates[0].件名)
            setAnswerContent(mailtemplates[0].本文)
        } else if (record.一時保存フラグ.value === 'true' && mailtemplates.length > 0) {
            setMethodSelect('メール')
            setMethodSelect((editContents.value.回答方法 || {}).value || '')
            setTitle((editContents.value.件名 || {}).value || '')
            setAnswerContent((editContents.value.回答内容 || {}).value || '')
        } 
    }, [mailtemplates, record, appNo, dict, formInfo, isTel, isRequery])

    // メール内容入力時に入力内容をまとめたオブジェクトをセット
    useEffect(() => {
        setMailContent({
            回答方法 : methodSelect,
            件名 : title,
            本文 : answerContent,
            添付ファイル : historyFiles || [],
        })
    }, [methodSelect, title, answerContent, historyFiles, setMailContent])

    // テンプレート選択ハンドラ
    const setTemplateHandler = v => {
        if (!mailtemplates) return
        if (v === '---') { 
            setTitle('')
            setAnswerContent('')
        } else {
            setTitle(record.回答履歴テーブル.value.filter(c =>
                ((c.value || {}).回答方法 || {}).value !== '電話').length > 1
                ? 'Re:' + mailtemplates[v].件名 : mailtemplates[v].件名 
            )
            setAnswerContent(mailtemplates[v].本文)
        }
        validateMsg.本文 = ''
        validateMsg.件名 = ''
        changeValidateMsg({...validateMsg})
        setTemplateOpt(v)
    }

    // 回答方法選択ハンドラ
    const setMethodSelectHandler = v => {
        if (!mailtemplates) return
        if (v === 'メール') {
            setTitle('')
            setTemplateOpt('---')
        }
        if (v === '電話') {
            setTitle(`【${record.受付No.value}】${dict.labels.電話回答内容}`)
            setTemplateOpt('---')
            setAnswerContent('')
        }
        validateMsg.本文 = ''
        validateMsg.件名 = ''
        changeValidateMsg({...validateMsg})
        setMethodSelect(v)
    }

    // 確認ボタン押下時ハンドラ
    const onClick = () => {
        if (!mailContent.本文 || mailContent.本文 === '') validateMsg.本文 = true
        if (!mailContent.件名 || mailContent.件名 === '') validateMsg.件名 = true
        if (Object.values(validateMsg).every(r => !r)) {
            setOperation('回答')
        } else {
            changeValidateMsg({...validateMsg})
            showMsg('error', dict.messages.入力エラー)
        }
    }

    const changeAnswerContent = (f) => {
        if (validateMsg.本文) {
            validateMsg.本文 = ''
            changeValidateMsg({...validateMsg})
        }
        setAnswerContent(f)
    }

    const changeTitle = (f) => {
        if (validateMsg.件名) {
            validateMsg.件名 = ''
            changeValidateMsg({...validateMsg})
        }
        setTitle(f)
    }

    return (<>
        <WcmsGroup groupTitle={dict.labels.回答履歴}>
            <table className={classes.root}>
                <thead>
                    <tr>
                        <th className="method">{dict.labels.回答方法}</th>
                        <th className="content">{dict.labels.回答内容}</th>
                        <th className="datetime">{dict.labels.送受信日時}</th>
                        <th  className="attachedFile">{dict.labels.添付ファイル}</th>
                        <th  className="confirm"></th>
                    </tr>
                </thead>
                <tbody>
                {
                // 回答行の表示非表示制御
                //　電話応対記録時 → 表示
                // 「未対応」→　割振り担当者のみ表示（管理者も）
                // 「受付済」→　回答担当者のみ表示（管理者も）
                (
                    isTel
                    ||
                    (record && record.問合せステータス.value === '未対応' && (isAdmin || isAssigner))
                    ||
                    ((record && (record.問合せステータス.value === '受付済み' || record.問合せステータス.value === '回答済み')) &&
                    (isAdmin || isAssigner || isReplier))
                )
                &&
                    <HistoryRow
                        key={(((record || {}).回答履歴テーブル || {}).value || []).length}
                        dict={dict}
                        canAnswer={true}
                        isTel={isTel}
                        index={
                            (((record || {}).回答履歴テーブル || {}).value || []).length === 0
                            ? 0
                            : (((record || {}).回答履歴テーブル || {}).value || []).length - 1
                        }
                        validateMsg={validateMsg}
                        historyFiles={historyFiles}
                        handleHistoryFiles={handleHistoryFiles}
                        methodSelect={methodSelect}
                        setMethodSelectHandler={setMethodSelectHandler}
                        templateOpt={templateOpt}
                        setTemplateHandler={setTemplateHandler}
                        mailtemplates={mailtemplates}
                        title={title}
                        changeTitle={changeTitle}
                        answerContent={answerContent}
                        changeAnswerContent={changeAnswerContent}
                        onClick={onClick}
                        record={record}
                    />
                }
                {record && (record.回答履歴テーブル.value.slice(0, record.回答履歴テーブル.value.length - 1) || []).reverse()
                    .map((c, i, a) => (
                    <HistoryRow
                        key={i}
                        dict={dict}
                        canAnswer={false}
                        isTel={isTel}
                        index={a.length - i - 1}
                        row={c}
                        validateMsg={validateMsg}
                        historyFiles={historyFiles}
                        handleHistoryFiles={handleHistoryFiles}
                        record={record}
                    />
                ))}
                </tbody>
            </table>
        </WcmsGroup>
    {!isTel &&
        <AnswerModal
            showMsg={showMsg}
            operation={operation}
            closeModal={closeModal}
            appNo={appNo}
            currentAppNo={currentAppNo}
            record={record}
            formInfo={formInfo}
            userInfo={userInfo}
            allDepartments={allDepartments}
            setAllDepartments={setAllDepartments}
            dict={dict}
            setErr={setErr}
            mailContent={mailContent}
            inquiryFiles={inquiryFiles}
            historyFiles={historyFiles}
            mailtemplate={getMailtemplates((currentAppNo || appNo), formInfo, record, userInfo, ['電話回答通知'], form)}
        />
    }
    </>)
}


export default History;
