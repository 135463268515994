import { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Amplify, { API, Storage } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import MaterialTable from 'material-table'
import { add, format } from 'date-fns'
import WcmsContent from './WcmsContent'
import Condition from './Condition'
import FormSelector from './FormSelector'
import awsconfig from './aws-exports'
import Spinner from './Spinner'
import InfoAccordion from './InfoAccordion'
import commonFunc from './commonFunc'
import constants from './constants'

const solidLine = 'solid 1px rgba(224, 224, 224, 1)'
Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const useStyles = makeStyles(theme => ({
    root: {
        '& .conditions': {
            marginBottom: 10,
            fontSize: 14,

            '& .condition-line': {
                display: 'flex',
                paddingTop: 8,
            },
            '& input': {
            },
        },
        '& .action-area': {
            display: 'flex',
            justifyContent: 'space-between',
            '& .buttons': {
                display: 'flex',
                margin: '0 -4px 4px',
                '& button': {
                    margin: '0 4px',
                },
            },
            '& .exportCSV-message': {
                display: 'flex',
                alignItems: 'center',
                fontSize: 14,
            }
        },
        '& .tel-resp': {
            display: 'flex',
            alignItems: 'center',
            '& .selector': {
                width: 300,
                marginRight: 6,
            },
        },
        '& .total': {
            fontSize: 14,
            textAlign: 'right',
        },
        '& .MuiTable-root': {
            fontSize: 11,
            borderCollapse: 'unset !important',
            '& tr': {
                '& th': {
                    fontSize: 11,
                    padding: 4,
                    background: '#153f93',
                    color: '#fff',
                    ...widths,
                    '&:nth-child(1) span': {
                        display: 'none',
                    },
                },
                '& td': {
                    padding: 4,
                    wordBreak: 'break-word',
                    ...widths,
                    borderBottom: solidLine,
                },
                '& div': {
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                    overflowX: 'scroll',
                    maxHeight: 70
                }
            },
            '& .MuiTableSortLabel-root': {
                color: '#fff',
                '&:hover': {
                    color: 'rgba(255, 255, 255, 0.54)',
                },
                '& .MuiTableSortLabel-icon:hover': {
                    color: 'rgba(255, 255, 255, 0.54)',
                },
                '&.MuiTableSortLabel-active': {
                    fontWeight: 'bold',
                    color: '#fff',
                    '& .MuiTableSortLabel-icon': {
                        color: '#fff',
                    }
                },
            }
        },
    },
}))

// レコード一覧の列の幅設定
const widths = [40, 115, 90, 80, 150, 90, 130, 90, 110, 100, 90, 90, 90]
    .reduce((o, w, i) => ({ ...o, [`&:nth-child(${i + 1})`]: { minWidth: w, maxWidth: w } }), {})

// レコード一覧のヘッダー定義
const headers = [
    '受付No',
    '問合せステータス',
    '言語',
    'お客様会社名',
    'お客様名',
    '問合せ製品',
    '回答担当者',
    'サブ担当者',
    'サイト名',
    '登録日',
    '受付日',
    '最終更新日',
]

// 検索条件from日時項目リスト
const fromDateParamKeys = ['登録日時from', '受付日時from', '更新日時from']
// 検索条件to日時項目リスト
const toDateParamKeys = ['登録日時to', '受付日時to', '更新日時to']

// 日付形式文字列をブラウザのタイムゾーンに合わせた日時形式に変換する（時間は00:00:00）
const dateToDatetimeFormat = (t, d) => {
    let datetime = ''
    if (t) {
        const devDatetime = new Date(`${t}T00:00:00`)
        // To項目の場合1日プラスする必要があるため、引数の値分日付を+-する
        const calcDatetime = add(devDatetime, { days: d })
        datetime = format(calcDatetime, "yyyy-MM-dd'T'HH:mm:ssXXX")
    }
    return datetime
}

//ソート比較関数
const sortNumber = (a, b) => {
    if (a.index === b.index) return 0
    if (a.index === '') return 1
    if (b.index === '') return -1
    return (Number(a.index) > Number(b.index)) ? 1 : -1
}

// 問合せ一覧画面
const List = ({
    isAdmin,            // システム管理者か否か
    userInfo,           // ログインユーザ情報
    formApps,           // 担当フォームアプリ情報
    formInfo,           // 問合せフォーム情報
    conditions,         // 検索条件
    changeCondition,    // 検索条件の値変更
    condFormName,       // 検索条件：フォーム名選択値
    changeFormName,     // 検索条件：フォーム名変更
    condUser,           // 検索条件：担当者
    changeCondUser,     // 検索条件：担当者変更
    condOperationUser,  // 検索条件：操作者
    changeCondOperationUser,   // 検索条件：操作者変更
    condForwardingMailAddress,   // 検索条件：転送先メールアドレス
    changeCondForwardingMailAddress,   // 検索条件：転送先メールアドレス変更
    noticeInfo,         // お知らせオブジェクト
    dict,               // ラベル表示用辞書
    setErr              // エラーセッター
}) => {
    const classes = useStyles()
    const history = useHistory()
    const [msg, setMsg] = useState(null)
    const [rows, setRows] = useState([])
    const [condFormNames, setCondFormNames] = useState([])
    const [telForm, setTelForm] = useState(null)
    const [telFormNames, setTelFormNames] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [spinnerOpen, setSpinnerOpen] = useState(false)
    const [firstSearch, setFirstSearch] = useState(true)

    // 辞書データ -> 選択肢用データ生成 関数
    const options = v => v.map(v => ({ label: v.name, value: v.value }))

    // レコード一覧のヘッダー設定
    const columns = headers.map(h => ({ field: h, title: dict.labels[h] }))

    /**
     * kintoneのアクセス数軽減のため、検索条件が誤っていないか確認する
     * @param conds object 検索条件
     * @return bool
     * エラーあり : true
     * エラーなし : false
     */
    const validate = useCallback(
        conds => {
            // 検索条件のキーと多言語対応のキー名称が異なるので置き換える
            const codeToLabelkeyObj = {
                登録日時: "登録日",
                受付日時: "受付日",
                更新日時: "最終更新日",
            }
            return Object.keys(conds).map(c => {
                // 日時の検索条件のfrom toが逆転していないこと
                const [res, code] = c.match(/^(.+)from$/) || []
                if (!res || !conds[`${code}from`] || !conds[`${code}to`]) return false
                if (conds[`${code}from`] > conds[`${code}to`]) {
                    setMsg({ type: 'error', text: dict.messages.日付逆転エラー.replace('{label}', dict.labels[codeToLabelkeyObj[code]]) })
                    return true
                }
                setMsg(null)
                return false
            })
                .some(r => r)
        }
        , [dict]
    )

    // 検索ボタン ハンドラ
    const handleSearch = useCallback(
        async () => {
            console.log('handleSearch')
            // システム管理者ではなく、担当フォームがない場合は問合せ検索APIを実行しない
            if (!isAdmin && !formApps.部署担当フォームアプリID.length) {
                setMsg({ type: 'info', text: dict.messages.検索結果0件 })
                return
            }
            if (validate(conditions)) return
            // 日付項目検索条件を日時形式に整形する
            let devConditions = fromDateParamKeys.reduce((a, c) => ({ ...a, [c]: dateToDatetimeFormat(conditions[c], 0) }), conditions)
            devConditions = toDateParamKeys.reduce((a, c) => ({ ...a, [c]: dateToDatetimeFormat(conditions[c], 1) }), devConditions)
            // AND検索のため不要な項目を渡すと結果0件となるため条件から削除
            delete devConditions.err
            console.log(devConditions)
            setSpinnerOpen(true)
            try {
                const { searchResult, resultCode, totalCount } = await API.post(apiname, '/searchContact', {
                    body: {
                        app: formApps,
                        isAdmin: isAdmin,
                        condition: devConditions,
                    },
                })
                if (resultCode !== '00') throw new Error(`問合せ検索API NG (${resultCode})`)
                const dictStatus = dict.values.問合せステータス.reduce((o, k) => ({ ...o, [k.value]: k.name }), {})
                setTotalCount(totalCount)
                setRows(searchResult.map(r => ({
                    受付No: r.受付No,
                    問合せステータス: r.問合せステータス.split('／').map(v => dictStatus[v] || '').join('／') + (r.一時保存フラグ ? `(${dict.labels.一時保存中})` : ''),
                    言語: r.言語,
                    お客様会社名: r.clientCompany,
                    お客様名: r.clientName,
                    問合せ製品: r.product,
                    回答担当者: r.担当者,
                    サブ担当者: r.サブ担当者 ? JSON.parse(r.サブ担当者).map(v => v.label).join('\n') : '',
                    サイト名: r.サイト === '-' ? r.サイト : <a href={r.サイト} target="_blank" rel="noreferrer">{r.サイト}</a>,
                    登録日: commonFunc.dateFormat(r.登録日時),
                    受付日: commonFunc.dateFormat(r.受付日時),
                    最終更新日: commonFunc.dateFormat(r.更新日時),
                    app: r.アプリID,
                    recNo: r.id
                })))
                if (totalCount !== searchResult.length) {
                    setMsg({ type: 'warning', text: dict.messages.件数制限.replace('{total}', totalCount).replace('{count}', searchResult.length) })
                } else if (!searchResult.length) {
                    setMsg({ type: 'info', text: dict.messages.検索結果0件 })
                } else {
                    setMsg(null)
                }
                setSpinnerOpen(false)
            } catch (e) {
                setSpinnerOpen(false)
                setErr(e)
            }
        }
        , [conditions, formApps, isAdmin, dict, setErr, validate]
    )
    // CSVエクスポートボタン押下時ハンドラ
    const handleExportCSV = useCallback(
        async () => {
            if (validate(conditions)) return
            setSpinnerOpen(true)
            const appNo = condFormName.id
            let devConditions = fromDateParamKeys.reduce((a, c) => ({ ...a, [c]: dateToDatetimeFormat(conditions[c], 0) }), conditions)
            delete devConditions.err
            devConditions = toDateParamKeys.reduce((a, c) => ({ ...a, [c]: dateToDatetimeFormat(conditions[c], 1) }), devConditions)

            // 現在日時を出力
            const exportDate = format(new Date(), "yyyyMMddHHmmss")
            const dateOffset = new Date().getTimezoneOffset()
            const fileName = `${condFormName.name}_${exportDate}`
            try {
                await API.post(apiname, '/exportCSV', {
                    body: {
                        app: appNo,
                        condition: devConditions,
                        date: exportDate,
                        dateOffset: dateOffset,
                        fileName: fileName,
                        lang: dict.lang
                    }
                })
                // 一定時間ごとにS3からファイルを取得する処理を実行
                // 一定時間経ってもファイルが取得できない場合はタイムアウトエラーを表示
                let csvData = ""
                let throttlingCheckText = ""
                let timeOutFlg = false
                let throttlingFlg = false
                //一定時間対象ファイルが取得できなかった場合、タイムアウトを表示
                setTimeout(() => { timeOutFlg = true }, constants.exportCSVTimeOut)
                // スロットリングチェック用ファイルが取得できず、一定時間経過した場合はエラーを表示
                setTimeout(() => { throttlingFlg = true }, constants.throttlingTimeOut)
                while (!csvData) {
                    try {
                        throttlingCheckText = await Storage.get(`${fileName}_ThrottlingCheck.txt`, { download: true, validateObjectExistence: true })
                        csvData = await Storage.get(`${fileName}.csv`, { download: true, validateObjectExistence: true })
                        commonFunc.downloadBlob(csvData.Body, `${fileName}.csv`)
                    } catch (e) {
                        console.log(`${fileName}.csv`)
                        if (timeOutFlg) {
                            alert(dict.messages.CSV出力タイムアウト)
                            break
                        }
                        if (throttlingFlg && !throttlingCheckText) {
                            alert(dict.messages.CSV出力実行エラー)
                            break
                        }
                        await commonFunc.wait(constants.exportCSVWait)
                    }
                }
                setSpinnerOpen(false)
            } catch (e) {
                setSpinnerOpen(false)
                setErr(e)
            }
        }
        , [conditions, dict, setErr, condFormName, validate]
    )

    // 検索条件のフォーム名／電話受付登録先のフォーム名の候補生成、初期検索の実行
    useEffect(() => {
        if (!formApps || !formInfo || !conditions || !firstSearch) return
        setFirstSearch(false)
        const createOptions = a => a.filter(id => formInfo[id]).map(id => ({
            id: id,
            code: formInfo[id].code,
            name: formInfo[id].formNames.find(n => n.lang === formInfo[id].lang).formName,
            index: formInfo[id].formSortNum
        }))

        const chargeInForms = (createOptions(formApps.部署担当フォームアプリID) || []).sort((a, b) => sortNumber(a, b))
        console.log(chargeInForms)

        setCondFormNames(chargeInForms)
        setTelFormNames(chargeInForms)
        handleSearch()

    }, [formApps, formInfo, conditions, firstSearch, handleSearch])

    // 検索条件がundefinedの場合はnullを返す
    if (!conditions) return null



    return (<>
        <WcmsContent
            subtitle={dict.titles.問い合わせ一覧}
            msg={msg}
            topChildren={noticeInfo.filter(r => (((r || {}).言語毎のお知らせ内容 || {}).value || []).some(c => (c.value.言語.value === dict.lang))
            ).map((r, i) => (
                <InfoAccordion
                    key={i}
                    summary={r.言語毎のお知らせ内容.value.find(c => c.value.言語.value === dict.lang).value.タイトル}
                    detail={r.言語毎のお知らせ内容.value.find(c => c.value.言語.value === dict.lang).value.お知らせ内容}
                />
            ))
            }
        >
            <div className={classes.root}>
                <div className="conditions">
                    <div className="condition-line">
                        <Condition
                            type="checkbox"
                            label={dict.labels.問合せステータス}
                            form={conditions}
                            name="問合せステータス"
                            options={options(dict.values.問合せステータス)}
                            changeCondition={changeCondition}
                        />
                    </div>
                    <div className="condition-line">
                        <Condition
                            label={dict.labels.受付No}
                            form={conditions}
                            name="受付No"
                            changeCondition={changeCondition}
                        />
                        <Condition
                            label={dict.labels.お客様名}
                            form={conditions}
                            name="clientName"
                            changeCondition={changeCondition}
                        />
                        <Condition
                            label={dict.labels.お客様会社名}
                            form={conditions}
                            name="clientCompany"
                            changeCondition={changeCondition}
                        />
                    </div>
                    <div className="condition-line">
                        <Condition
                            label={dict.labels.問合せ製品}
                            form={conditions}
                            name="product"
                            changeCondition={changeCondition}
                        />
                        <Condition
                            label={dict.labels.サイト名}
                            form={conditions}
                            name="サイト"
                            changeCondition={changeCondition}
                        />
                        <Condition
                            type="mail"
                            label={dict.labels.担当者}
                            form={conditions}
                            name="担当者メールアドレス"
                            selectValue={condUser}
                            changeCondition={changeCondUser}
                            userInfo={userInfo}
                            setErr={setErr}
                            dict={dict}
                            ignoreMailRegex={true}
                        />
                    </div>
                    <div className="condition-line">
                        <Condition
                            type="combo"
                            label={dict.labels.フォーム名}
                            form={conditions}
                            name="フォーム名"
                            selectValue={condFormName}
                            options={condFormNames}
                            changeCondition={changeFormName}
                        />
                        <Condition
                            label={dict.labels.言語}
                            form={conditions}
                            name="言語"
                            options={options(dict.values.言語)}
                            changeCondition={changeCondition}
                        />
                        <Condition
                            type="mail"
                            label={dict.labels.操作者}
                            form={conditions}
                            name="操作者メールアドレス"
                            selectValue={condOperationUser}
                            changeCondition={changeCondOperationUser}
                            userInfo={userInfo}
                            setErr={setErr}
                            dict={dict}
                            ignoreMailRegex={false}
                        />
                    </div>
                    <div className="condition-line">
                        <Condition
                            type={'text'}
                            label={dict.labels.キーワード検索}
                            form={conditions}
                            name="キーワード検索"
                            changeCondition={changeCondition}
                        />
                        <Condition
                            type="mail"
                            label={dict.labels.転送先メールアドレス}
                            form={conditions}
                            name="転送先メールアドレス"
                            selectValue={condForwardingMailAddress}
                            changeCondition={changeCondForwardingMailAddress}
                            userInfo={userInfo}
                            setErr={setErr}
                            dict={dict}
                            ignoreMailRegex={false}
                        />
                    </div>
                    <div className="condition-line">
                        <Condition
                            type="dates"
                            label={dict.labels.登録日}
                            form={conditions}
                            name={['登録日時from', '登録日時to']}
                            changeCondition={changeCondition}
                        />
                        <Condition
                            type="dates"
                            label={dict.labels.受付日}
                            form={conditions}
                            name={['受付日時from', '受付日時to']}
                            changeCondition={changeCondition}
                        />
                        <Condition
                            type="dates"
                            label={dict.labels.最終更新日}
                            form={conditions}
                            name={['更新日時from', '更新日時to']}
                            changeCondition={changeCondition}
                        />
                    </div>
                    <div className="condition-line">
                    </div>
                </div>
                <div className="action-area">
                    <div className="buttons">
                        <Button
                            variant="contained"
                            onClick={handleSearch}
                            disabled={Object.values(conditions.err || {}).some(v => v)}
                        >
                            {dict.labels.検索}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleExportCSV}
                            disabled={!conditions.フォーム名}
                        >
                            {dict.labels.CSV出力}
                        </Button>
                        <div className="exportCSV-message">
                            {dict.messages.CSV出力時注意点}
                        </div>
                    </div>
                    <div className="tel-resp">
                        <div className="selector">
                            <FormSelector
                                value={telForm}
                                options={telFormNames}
                                changeValue={setTelForm}
                            />
                        </div>
                        <Button
                            variant="contained"
                            disabled={!telForm}
                            onClick={(e) => { history.push(`/detail/${telForm.id}`) }}
                        >
                            {dict.labels.電話受付登録}
                        </Button>
                    </div>
                </div>
                <div className="total">{`${rows.length}/${totalCount}`}</div>
                <MaterialTable
                    columns={columns}
                    data={rows}
                    actions={[{
                        icon: 'edit',
                        onClick: (e, r) => history.push(`/detail/${r.app}?r=${r.recNo}`)
                    }]}
                    options={{
                        paging: false,
                        toolbar: false,
                        draggable: false,
                        padding: 'dense',
                        maxBodyHeight: 'calc(100vh - 390px)',
                    }}
                    components={{
                        Cell: props => {
                            return (
                                <td>
                                    <div>{props.value}</div>
                                </td>
                            )
                        }
                    }}
                />
            </div>
            <Spinner spinnerOpen={spinnerOpen} />
        </WcmsContent>
    </>)
}

export default List
