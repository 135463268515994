import { format } from 'date-fns'

// メール置換対象項目のフィールドID
const replaceTargets = [
    'client_transfer',
    'emailAddress_transfer',
    'companyName_search',
    'yourDivision_transfer',
    'yourPosition_transfer',
    '住所_pc_address_transfer',
    'phone_transfer',
    'countries_transfer',
    '住所_st_address_transfer',
    'product_search',
    'applications_transfer',
    'postalCode_transfer',
    'address_transfer',
    'commentsQuestions_transfer'
]

// メール本文置換処理
const mailContentsReplace = (content, appNo, userInfo, formInfo, record, form) => {
    const devContent = replaceTargets.reduce((a, c) => 
        a.replace(new RegExp(`{${c}}`, 'g'), (((record || {})[c] || {}).value || ''))
    , content)
    return devContent
        .replace(/{AD_companyName}/g, ((userInfo || {})['custom:company'] || ''))
        .replace(/{AD_department}/g, ((userInfo || {})['custom:department'] || ''))
        .replace(/{AD_displayName}/g, ((userInfo || {}).name || ''))
        .replace(/{AD_emailAddress}/g, ((userInfo || {}).email || ''))
        .replace(
            /{replyContents}/g,
            ((((((record || {}).回答履歴テーブル || {}).value || []).slice().reverse()[1] || {}).value || {}).回答内容 || {}).value || ''
        )
        .replace(/{receiptNo}/g, (((record || {}).受付No || {}).value || ''))
        .replace(/{formTitle}/g, ((((formInfo || {})[appNo] || {}).formNames || []).find(e => e.lang === formInfo[appNo].lang).formName || ''))
        .replace(
            /{allInquiryInfo}/g,
            ((form.filter(a => a.label).map(o => (`${o.label} : ${o.value || ""}`))).join("\n")) || ''
        )
        .replace(/{wcmsDetailURL}/g, `https://${document.domain}/detail/${appNo}?r=${record.$id.value}`)
}

// メール件名置換処理
const mailTitleReplace = (content, appNo, formInfo, record) => { 
    return content
        .replace(/{receiptNo}/g, (((record || {}).受付No || {}).value || ''))
        .replace(/{formTitle}/g, ((((formInfo || {})[appNo] || {}).formNames || []).find(e => e.lang === formInfo[appNo].lang).formName || ''))
}

// 日時フォーマット
const dateFormat = t => t ? format(new Date(t), 'yyyy/MM/dd HH:mm:ss') : ''

// ウェイト処理
const wait = async (ms) => new Promise(resolve => setTimeout(resolve, ms));

// CSVファイル出力用関数
function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

const commonFunc = {
    mailContentsReplace : mailContentsReplace,
    mailTitleReplace : mailTitleReplace,
    dateFormat : dateFormat,
    wait : wait,
    downloadBlob : downloadBlob
}


export default commonFunc