import { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
    root : {
        '& .MuiAccordionSummary-content' : {
            margin : '12px !important' 
        },
    },
}))

const InfoAccordion = memo(({
    summary,
    detail
}) => {
    const classes = useStyles()

    return (
        <Accordion className={classes.root}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className="accordion-summary" dangerouslySetInnerHTML={{ __html : summary.value}} />
            </AccordionSummary>
            <AccordionDetails>
                <div className="accordion-detail" dangerouslySetInnerHTML={{ __html : detail.value}} />
            </AccordionDetails>
        </Accordion>
    )
})

export default InfoAccordion