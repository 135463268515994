import { TablePagination } from "@material-ui/core";

/** Material-tableをページングで表示するためのコンポーネント
*   ※バージョンの関係で本コンポーネントが必要
*   material-tableを使用する際にcomponent propsのPaginationキーに設定する
*/
export default function PatchedPagination(TablePaginationProps) {
	const {
		ActionsComponent,
		onChangePage,
		onChangeRowsPerPage,
		...tablePaginationProps
	} = TablePaginationProps;

	return (
		<TablePagination
			{...tablePaginationProps}
			// @ts-expect-error onChangePage was renamed to onPageChange
			onPageChange={onChangePage}
			onRowsPerPageChange={onChangeRowsPerPage}
			ActionsComponent={(subprops) => {
				const { onPageChange, ...actionsComponentProps } = subprops;
				return (
					// @ts-expect-error ActionsComponent is provided by material-table
					<ActionsComponent
						{...actionsComponentProps}
						onChangePage={onPageChange}
					/>
				);
			}}
		/>
	);
}